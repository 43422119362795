import React from 'react'
import PropTypes from 'prop-types'

import Dropdown from '../inputs/Dropdown'
import MainInput from '../inputs/MainInput'

const MilestoneTableFilterRow = (props) => {
  const { programFilter, filterProgramOptions, setProgramFilter } = props
  const { statusFilter, filterStatusOptions, setStatusFilter } = props
  const { descriptionFilter, setDescriptionFilter } = props

  const { studyFilter, filterStudyOptions, setStudyFilter } = props

  return (
    <tr>
      <td />
      <td />
      <td className="u-padding--zero">
        <Dropdown
          placeholder="Filter study"
          dropdownClassNames="dropdown-select u-margin--zero"
          selectedOption={ studyFilter }
          options={ filterStudyOptions }
          onSelect={ setStudyFilter }
          clearable={ true }
          id="dropdown-milestones-filter-study-name" />
      </td>
      <td className="u-padding--zero">
        <Dropdown
          placeholder="Filter program"
          dropdownClassNames="dropdown-select u-margin--zero"
          selectedOption={ programFilter }
          options={ filterProgramOptions }
          onSelect={ setProgramFilter }
          clearable={ true }
          id="dropdown-milestones-filter-program-name" />
      </td>
      <td>
        <MainInput
          placeholder="Filter description"
          value={ descriptionFilter }
          inputClass="with-min-width"
          onInputChanged={ setDescriptionFilter }
          id="input-milestones-filter-description" />
      </td>
      <td className="u-padding--zero">
        <Dropdown
          placeholder="Filter status"
          dropdownClassNames="dropdown-select u-margin--zero"
          selectedOption={ statusFilter }
          options={ filterStatusOptions }
          onSelect={ setStatusFilter }
          clearable={ true }
          id="dropdown-milestones-filter-status" />
      </td>
    </tr>
  )
}

MilestoneTableFilterRow.propTypes = {
  // filter by study id
  studyFilter: PropTypes.object,
  filterStudyOptions: PropTypes.array,
  setStudyFilter: PropTypes.func.isRequired,
  // filter by program name
  programFilter: PropTypes.object,
  filterProgramOptions: PropTypes.array,
  setProgramFilter: PropTypes.func.isRequired,
  // filter by milestone status
  statusFilter: PropTypes.object,
  filterStatusOptions: PropTypes.array,
  setStatusFilter: PropTypes.func.isRequired,
  // filter by description
  descriptionFilter: PropTypes.string,
  setDescriptionFilter: PropTypes.func.isRequired,
}

MilestoneTableFilterRow.defaultProps = {
  // filter by study id
  studyFilter: null,
  filterStudyOptions: [],
  // filter by program name
  programFilter: null,
  filterProgramOptions: [],
  // filter by milestone status
  statusFilter: null,
  filterStatusOptions: [],
  // filter by description
  descriptionFilter: "",
}

export default MilestoneTableFilterRow
