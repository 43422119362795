import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // all milestones
  fetchMilestones: null,
  fetchMilestonesSuccess: ['milestones'],
  fetchMilestonesFailure: ['error'],
  // milestones by study
  fetchMilestonesByStudy: ['studyId'],
  fetchMilestonesByStudySuccess: ['milestones'],
  fetchMilestonesByStudyFailure: ['error'],
  // update
  updateMilestone: ['studyId', 'milestoneId', 'updatedMilestone'],
  updateMilestoneSuccess: ['updatedMilestone'],
  updateMilestoneFailure: ['error'],
  resetUpdateMilestoneError: null,
  // cancel
  cancelMilestone: ['studyId', 'milestoneId'],
  cancelMilestoneSuccess: ['canceledMilestone'],
  cancelMilestoneFailure: ['error'],
  resetCancelMilestoneError: null,
  // add
  addMilestone: ['studyId', 'milestoneToAdd'],
  addMilestoneSuccess: ['addedMilestone'],
  addMilestoneFailure: ['error'],
  resetAddMilestoneError: null,
})

export const MilestoneTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // all milestones
  milestonesList: [],
  fetchMilestonesError: null,
  busyFetchingMilestones: false,
  // milestones by study
  milestonesByStudyList: [],
  fetchMilestonesByStudyError: null,
  busyFetchingMilestonesByStudy: false,
  // cancel
  idOfMilestoneToCancel: null,
  cancelMilestoneError: null,
  busyCancelingMilestone: false,
  // update
  updatedMilestone: null,
  updateMilestoneError: null,
  busyUpdatingMilestone: false,
  // add
  milestoneToAdd: null,
  addMilestoneError: null,
  busyAddingMilestone: false,
})

/* ------------- Reducers ------------- */
// all milestones
export const fetchMilestonesByStudy = state => state.merge({
  busyFetchingMilestonesByStudy: true,
  milestonesByStudyList: [],
  fetchMilestonesByStudyError: null,
})
export const fetchMilestonesByStudySuccess = (state, { milestones }) => state.merge({
  busyFetchingMilestonesByStudy: false,
  milestonesByStudyList: milestones,
})
export const fetchMilestonesByStudyFailure = (state, { error }) => state.merge({
  busyFetchingMilestonesByStudy: false,
  fetchMilestonesByStudyError: error,
})
// milestones by study
export const fetchMilestones = state => state.merge({
  busyFetchingMilestones: true,
  milestonesList: [],
  fetchMilestonesError: null,
})
export const fetchMilestonesFailure = (state, { error }) => state.merge({
  busyFetchingMilestones: false,
  fetchMilestonesError: error,
})
export const fetchMilestonesSuccess = (state, { milestones }) => state.merge({
  busyFetchingMilestones: false,
  milestonesList: milestones,
})
// cancel milestone
export const cancelMilestone = (state, { milestoneId }) => state.merge({
  busyCancelingMilestone: true,
  idOfMilestoneToCancel: milestoneId,
  cancelMilestoneError: null,
})
export const cancelMilestoneFailure = (state, { error }) => state.merge({
  busyCancelingMilestone: false,
  cancelMilestoneError: error,
})

export const cancelMilestoneSuccess = (state, { canceledMilestone }) => {
  const idx = state.milestonesByStudyList.findIndex(e => e.id === canceledMilestone.id)
  
  // the composition of milestonesByStudyList below will break if idx == -1 !!!
  return state.merge({
    busyCancelingMilestone: false,
    idOfMilestoneToCancel: null,
    milestonesByStudyList: [
      ...state.milestonesByStudyList.slice(0, idx), // everything before current study
      {
        ...state.milestonesByStudyList[idx],
        ...canceledMilestone,
      },
      ...state.milestonesByStudyList.slice(idx + 1), // everything after current study
    ],
  })
}

export const resetCancelMilestoneError = state => state.merge({ cancelMilestoneError: null })
// update milestone
export const updateMilestone = (state, { updatedMilestone }) => state.merge({
  busyUpdatingMilestone: true,
  updatedMilestone,
  updateMilestoneError: null,
})

export const updateMilestoneSuccess = (state, { updatedMilestone }) => {
  const idx = state.milestonesByStudyList.findIndex(e => e.id === updatedMilestone.id)
  
  // the composition of milestonesByStudyList below will break if idx == -1 !!!
  return state.merge({
    busyUpdatingMilestone: false,
    updatedMilestone: null,
    milestonesByStudyList: [
      ...state.milestonesByStudyList.slice(0, idx), // everything before current study
      {
        ...state.milestonesByStudyList[idx],
        ...updatedMilestone,
      },
      ...state.milestonesByStudyList.slice(idx + 1), // everything after current study
    ],
  })
}

export const updateMilestoneFailure = (state, { error }) => state.merge({
  busyUpdatingMilestone: false,
  updateMilestoneError: error,
})
export const resetUpdateMilestoneError = state => state.merge({ updateMilestoneError: null })
// add milestone
export const addMilestone = (state, { milestoneToAdd }) => state.merge({
  busyAddingMilestone: true,
  milestoneToAdd,
  addMilestoneError: null,
})
export const addMilestoneSuccess = (state, { addedMilestone }) => state.merge({
  busyAddingMilestone: false,
  milestoneToAdd: null,
  milestonesByStudyList: [...state.milestonesByStudyList, addedMilestone],
})
export const addMilestoneFailure = (state, { error }) => state.merge({
  busyAddingMilestone: false,
  addMilestoneError: error,
})
export const resetAddMilestoneError = state => state.merge({ addMilestoneError: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // all milestones
  [Types.FETCH_MILESTONES]: fetchMilestones,
  [Types.FETCH_MILESTONES_SUCCESS]: fetchMilestonesSuccess,
  [Types.FETCH_MILESTONES_FAILURE]: fetchMilestonesFailure,
  // milestones by study
  [Types.FETCH_MILESTONES_BY_STUDY]: fetchMilestonesByStudy,
  [Types.FETCH_MILESTONES_BY_STUDY_SUCCESS]: fetchMilestonesByStudySuccess,
  [Types.FETCH_MILESTONES_BY_STUDY_FAILURE]: fetchMilestonesByStudyFailure,
  // cancel milestone
  [Types.CANCEL_MILESTONE]: cancelMilestone,
  [Types.CANCEL_MILESTONE_SUCCESS]: cancelMilestoneSuccess,
  [Types.CANCEL_MILESTONE_FAILURE]: cancelMilestoneFailure,
  [Types.RESET_CANCEL_MILESTONE_ERROR]: resetCancelMilestoneError,
  // update milestone
  [Types.UPDATE_MILESTONE]: updateMilestone,
  [Types.UPDATE_MILESTONE_SUCCESS]: updateMilestoneSuccess,
  [Types.UPDATE_MILESTONE_FAILURE]: updateMilestoneFailure,
  [Types.RESET_UPDATE_MILESTONE_ERROR]: resetUpdateMilestoneError,
  // add milestone
  [Types.ADD_MILESTONE]: addMilestone,
  [Types.ADD_MILESTONE_SUCCESS]: addMilestoneSuccess,
  [Types.ADD_MILESTONE_FAILURE]: addMilestoneFailure,
  [Types.RESET_ADD_MILESTONE_ERROR]: resetAddMilestoneError,
})
