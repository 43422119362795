import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Routes, useParams, useNavigate } from 'react-router-dom'
import { is, isNil, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import cc from 'classcat'

import withRouter from '../../components/wrapperReactRouterDom'
import StudyActions from '../../redux/StudyRedux'
import CountryActions from '../../redux/CountryRedux'
import MilestoneActions from '../../redux/MilestoneRedux'
import BaseModal from '../../components/modals/BaseModal'
import EditStudyForm from '../../components/modals/EditStudyForm'
import CollapsePicker from '../../components/collapsePicker/CollapsePicker'
import CollapsePickerItem from '../../components/collapsePicker/CollapsePickerItem'
import Icon from '../../components/Icon'
import StudyLink from '../../components/links/StudyLink'
import SidebarButton from '../../components/buttons/SidebarButton'

function SelectedStudy({ studies }) {
  const studyId = useParams().study_id
  const matchingStudy = !isNil(studyId) && is(Array, studies) && !isEmpty(studies) && studies.find(study => study.id === studyId)

  return matchingStudy && !isNilOrEmpty(matchingStudy) ? (
    <CollapsePickerItem
      itemClass="selected collapsepicker-item--preview"
      onItemClick={ () => { } }
      tabIndex={ -1 }>
      <span>
        { `${matchingStudy.program} - ` }
        <strong>
          { matchingStudy.id }
        </strong>
      </span>
    </CollapsePickerItem>) : null 
}

function SidebarButtonGlobalConfiguration() {
  const navigate = useNavigate()

  return (
    <SidebarButton
      buttonClass="blue-button"
      handleClick={ () => navigate('/globalconfiguration') }
      id="button-all-milestones">
      <Icon
        name="configure"
        color="white" />
      <span>Global Configuration</span>
    </SidebarButton>
  )
}

function SidebarButtonMilestones({ fetchMilestones }) {
  const navigate = useNavigate()

  return (
    <SidebarButton
      buttonClass="blue-button"
      handleClick={ () => { fetchMilestones(); navigate('/milestones') } }
      id="button-all-milestones">
      <Icon
        name="tasks"
        color="white" />
      <span>All Milestones</span>
    </SidebarButton>
  )
}

function NavigateAfterNewStudy({ studyId }) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/study/${studyId}`)
  }, [studyId, navigate])

  return <div />
}

export class SidebarContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shownPicker: 0,
      showNewStudyModal: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // CREATE STUDY
    const { newStudy, resetAddStudyError } = this.props
    
    const newStudyHasBeenAdded = newStudy && !nextProps.newStudy && !nextProps.addStudyError
    if (newStudyHasBeenAdded) {
      this._closeNewStudyModal(resetAddStudyError)()
    }
  }

  render() {
    const { children, studies, sidebarCollapsed, history, newStudy } = this.props
    const { busyAddingStudy, addStudy, addStudyError, resetAddStudyError } = this.props
    const { fetchStudy, fetchCountriesByStudyId, fetchMilestones } = this.props
    const { shownPicker, showNewStudyModal } = this.state

    const hasStudies = !isNilOrEmpty(studies)

    const sidebarClassNames = cc([
      'sidebar-container',
      { "sidebar-container--active": sidebarCollapsed },
    ])

    return (
      <div className={ sidebarClassNames }>
        { /* STUDIES */ }
        { hasStudies && this._renderStudyPicker(shownPicker, studies, fetchStudy, fetchCountriesByStudyId, history) }
        { this._renderCreateStudyButton() }
        { this._renderMilestonesButton(fetchMilestones) }
        { this._renderGlobalConfigurationButton() }
        { showNewStudyModal && this._renderNewStudyModal(addStudy, resetAddStudyError, addStudyError, busyAddingStudy, studies) }
        { newStudy && <NavigateAfterNewStudy studyId={ newStudy.id } /> }
        { /* PARENT CHILDREN */ }
        { children }
      </div>
    )
  }

  _renderMilestonesButton(fetchMilestones) {
    return <SidebarButtonMilestones fetchMilestones={ fetchMilestones } />
  }
  
  _renderGlobalConfigurationButton() { 
    return <SidebarButtonGlobalConfiguration />
  }

  _renderCreateStudyButton() {
    return (
      <SidebarButton
        buttonClass="green-button"
        handleClick={ this._openNewStudyModal }
        id="button-create-study">
        <Icon
          name="plus"
          color="white" />
        <span>Create new study</span>
      </SidebarButton>
    )
  }

  _renderStudyPicker(shownPicker, studies, fetchStudy, fetchCountriesByStudyId, history) {
    return (
      <CollapsePicker
        isOpen={ shownPicker === 1 }
        title="STUDIES"
        selecterTitle="Study Overview"
        onSelectTitle="Select a study"
        containerClass="green-picker"
        onSelecterClick={ this._openCertainPicker(shownPicker, 1) }>
        { shownPicker === 1 && this._renderStudyLinks(studies, fetchStudy, fetchCountriesByStudyId, history) }
        { shownPicker !== 1 && this._renderSelectedStudy(studies) }
      </CollapsePicker>
    )
  }

  _renderStudyLinks(studies, fetchStudy, fetchCountriesByStudyId, history) {
    return is(Array, studies) && !isEmpty(studies) && studies.map(study => (
      <StudyLink
        study={ study }
        handleClick={ this._handleStudySelection(fetchStudy, fetchCountriesByStudyId, history) }
        key={ study.id } />
    ))
  }

  _renderSelectedStudy = studies => (
    <Routes>
      <Route
        path="/study/:study_id/*"
        element={ <SelectedStudy studies={ studies } /> } />
    </Routes>
  )

  _handleStudySelection = (fetchStudy, fetchCountriesByStudyId) => studyId => () => {
    fetchStudy(studyId)
    fetchCountriesByStudyId(studyId)
    
    this._closePicker()
  }

  // SIDEBAR PICKER(S)
  _openCertainPicker = (currentPickerShown, pickerToOpen) => () => {
    this.setState(() => ({ shownPicker: currentPickerShown === pickerToOpen ? 0 : pickerToOpen }))
  }

  _closePicker = () => {
    this.setState(() => ({ shownPicker: 0 }))
  }

  // CREATE STUDY
  _renderNewStudyModal(addStudy, resetAddStudyError, addStudyError, busyAddingStudy, studies) {
    return (
      <BaseModal
        title="New Study"
        handleClose={ this._closeNewStudyModal(resetAddStudyError) }
        forceInteraction={ busyAddingStudy }>
        <EditStudyForm
          handleCanceled={ this._closeNewStudyModal(resetAddStudyError) }
          handleConfirmed={ this._newStudyConfirmed(addStudy) }
          loading={ busyAddingStudy }
          error={ addStudyError }
          studies={ studies } />
      </BaseModal>
    )
  }

  _openNewStudyModal = () => {
    this.setState(() => ({ showNewStudyModal: true }))
  }

  _closeNewStudyModal = resetAddStudyError => () => {
    this.setState(() => ({ showNewStudyModal: false }))
    resetAddStudyError()
  }

  _newStudyConfirmed = addStudy => (newStudy) => {
    addStudy(newStudy)
  }
}

SidebarContainer.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
  sidebarCollapsed: PropTypes.bool.isRequired,
  // milestones
  fetchMilestones: PropTypes.func.isRequired,
  // countries
  fetchCountriesByStudyId: PropTypes.func.isRequired,
  // study
  fetchStudy: PropTypes.func.isRequired,
  // studies
  studies: PropTypes.array,
  // add study
  newStudy: PropTypes.object,
  addStudyError: PropTypes.object,
  addStudy: PropTypes.func.isRequired,
  resetAddStudyError: PropTypes.func.isRequired,
  busyAddingStudy: PropTypes.bool.isRequired,
}

SidebarContainer.defaultProps = {
  children: null,
  // studies
  studies: [],
  // add study
  newStudy: null,
  addStudyError: null,
}

export const mapStateToProps = state => ({
  study: state.studies.study,
  studies: state.studies.studyList,
  newStudy: state.studies.newStudy,
  addStudyError: state.studies.addStudyError,
  busyAddingStudy: state.studies.busyAddingStudy,
})

export const mapDispatchToProps = dispatch => ({
  addStudy: newStudy => dispatch(StudyActions.addStudy(newStudy)),
  fetchStudy: studyId => dispatch(StudyActions.fetchStudy(studyId)),
  resetAddStudyError: () => dispatch(StudyActions.resetAddStudyError()),
  fetchCountriesByStudyId: studyId => dispatch(CountryActions.fetchCountriesByStudyId(studyId)),
  fetchMilestones: () => dispatch(MilestoneActions.fetchMilestones()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer))
