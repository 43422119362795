import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // all investigators
  fetchInvestigators: null,
  fetchInvestigatorsSuccess: ['investigators'],
  fetchInvestigatorsFailure: ['error'],
  // investigators by study
  fetchInvestigatorsByStudy: ['studyId'],
  fetchInvestigatorsByStudySuccess: ['investigators'],
  fetchInvestigatorsByStudyFailure: ['error'],
  // delete investigator
  deleteInvestigator: ['studyId', 'countryId', 'investigatorToDelete'],
  deleteInvestigatorSuccess: null,
  deleteInvestigatorFailure: ['error'],
  resetDeleteInvestigatorError: null,
  // update investigator
  updateInvestigator: ['studyId', 'countryId', 'investigatorToUpdate'],
  updateInvestigatorSuccess: null,
  updateInvestigatorFailure: ['error'],
  resetUpdateInvestigatorError: null,
  // add investigator
  addInvestigator: ['studyId', 'countryId', 'newInvestigator'],
  addInvestigatorSuccess: ['investigator'],
  addInvestigatorFailure: ['error'],
  resetAddInvestigatorError: null,
})

export const InvestigatorTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // all investigators
  investigatorList: [],
  fetchInvestigatorsError: null,
  busyFetchingInvestigators: false,
  // investigators by study
  investigatorsByStudyList: [],
  fetchInvestigatorsByStudyError: null,
  busyFetchingInvestigatorsByStudy: false,
  // delete investigator
  investigatorToDelete: null,
  deleteInvestigatorError: null,
  busyDeletingInvestigator: false,
  // add investigator
  newInvestigator: null,
  addInvestigatorError: null,
  busyAddingInvestigator: false,
  // update investigator
  investigatorToUpdate: null,
  updateInvestigatorError: null,
  busyUpdatingInvestigator: false,
})

/* ------------- Reducers ------------- */

// all investigators
export const fetchInvestigators = state => state.merge({
  busyFetchingInvestigators: true,
  investigatorList: [],
  fetchInvestigatorsError: null,
})
export const fetchInvestigatorsSuccess = (state, { investigators }) => state.merge({
  busyFetchingInvestigators: false,
  investigatorList: investigators,
})
export const fetchInvestigatorsFailure = (state, { error }) => state.merge({
  busyFetchingInvestigators: false,
  fetchInvestigatorsError: error,
})
// investigators by study
export const fetchInvestigatorsByStudy = state => state.merge({
  busyFetchingInvestigatorsByStudy: true,
  investigatorsByStudyList: [],
  fetchInvestigatorsByStudyError: null,
})
export const fetchInvestigatorsByStudySuccess = (state, { investigators }) => state.merge({
  busyFetchingInvestigatorsByStudy: false,
  investigatorsByStudyList: investigators,
})
export const fetchInvestigatorsByStudyFailure = (state, { error }) => state.merge({
  busyFetchingInvestigatorsByStudy: false,
  fetchInvestigatorsByStudyError: error,
})
// delete investigator
export const deleteInvestigator = (state, { investigatorToDelete }) => state.merge({
  busyDeletingInvestigator: true,
  investigatorToDelete,
  deleteInvestigatorError: null,
})
export const deleteInvestigatorSuccess = state => state.merge({
  busyDeletingInvestigator: false,
  investigatorToDelete: null,
})
export const deleteInvestigatorFailure = (state, { error }) => state.merge({
  busyDeletingInvestigator: false,
  deleteInvestigatorError: error,
})
export const resetDeleteInvestigatorError = state => state.merge({ deleteInvestigatorError: null })
// update investigator
export const updateInvestigator = (state, { investigatorToUpdate }) => state.merge({
  busyUpdatingInvestigator: true,
  investigatorToUpdate,
  updateInvestigatorError: null,
})
export const updateInvestigatorSuccess = state => state.merge({
  busyUpdatingInvestigator: false,
  investigatorToUpdate: null,
})
export const updateInvestigatorFailure = (state, { error }) => state.merge({
  busyUpdatingInvestigator: false,
  updateInvestigatorError: error,
})
export const resetUpdateInvestigatorError = state => state.merge({ updateInvestigatorError: null })
// add investigator
export const addInvestigator = (state, { newInvestigator }) => state.merge({
  busyAddingInvestigator: true,
  newInvestigator,
  addInvestigatorError: null,
})
export const addInvestigatorSuccess = state => state.merge({
  busyAddingInvestigator: false,
  newInvestigator: null,
})
export const addInvestigatorFailure = (state, { error }) => state.merge({
  busyAddingInvestigator: false,
  addInvestigatorError: error,
})
export const resetAddInvestigatorError = state => state.merge({ addInvestigatorError: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // all investigators
  [Types.FETCH_INVESTIGATORS]: fetchInvestigators,
  [Types.FETCH_INVESTIGATORS_SUCCESS]: fetchInvestigatorsSuccess,
  [Types.FETCH_INVESTIGATORS_FAILURE]: fetchInvestigatorsFailure,
  // investigators by study
  [Types.FETCH_INVESTIGATORS_BY_STUDY]: fetchInvestigatorsByStudy,
  [Types.FETCH_INVESTIGATORS_BY_STUDY_SUCCESS]: fetchInvestigatorsByStudySuccess,
  [Types.FETCH_INVESTIGATORS_BY_STUDY_FAILURE]: fetchInvestigatorsByStudyFailure,
  // delete investigator
  [Types.DELETE_INVESTIGATOR]: deleteInvestigator,
  [Types.DELETE_INVESTIGATOR_SUCCESS]: deleteInvestigatorSuccess,
  [Types.DELETE_INVESTIGATOR_FAILURE]: deleteInvestigatorFailure,
  [Types.RESET_DELETE_INVESTIGATOR_ERROR]: resetDeleteInvestigatorError,
  // update investigator
  [Types.UPDATE_INVESTIGATOR]: updateInvestigator,
  [Types.UPDATE_INVESTIGATOR_SUCCESS]: updateInvestigatorSuccess,
  [Types.UPDATE_INVESTIGATOR_FAILURE]: updateInvestigatorFailure,
  [Types.RESET_UPDATE_INVESTIGATOR_ERROR]: resetUpdateInvestigatorError,
  // add investigator
  [Types.ADD_INVESTIGATOR]: addInvestigator,
  [Types.ADD_INVESTIGATOR_SUCCESS]: addInvestigatorSuccess,
  [Types.ADD_INVESTIGATOR_FAILURE]: addInvestigatorFailure,
  [Types.RESET_ADD_INVESTIGATOR_ERROR]: resetAddInvestigatorError,
})
