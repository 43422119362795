import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import history from './HistoryHelper'

const ReactAppInsights = {
  init(instrumKey) {
    const appInsights = new ApplicationInsights({ config: { instrumentationKey: instrumKey } })

    appInsights.loadAppInsights()
    appInsights.trackPageView()

    // track the new page view on any router change and display url
    history.listen((location) => {
      appInsights.trackPageView(location.pathname)
    })
  },
}
export default ReactAppInsights
