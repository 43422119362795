/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { isNil, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import PropTypes from 'prop-types'

import MainInput from '../inputs/MainInput'
import MainTextArea from '../inputs/MainTextArea'
import MainInputGroup from '../inputs/MainInputGroup'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

// eslint-disable-next-line react/prefer-stateless-function
class EditSiteFromCountryForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)

    this.state = {
      inputSiteId: null,
      inputPhysicalSite: null,
      inputPatientIdRangeStart: null,
      inputPatientIdRangeEnd: null,
      inputReason: null,
      inputUsername: window.sessionHandler.loggedInUser.userName,
      inputPassword: null,
    }
  }

  static getDerivedStateFromProps(props, state) {
    // only set the state the 1st time here (i.e. when it's in its all-null state)
    if (state.inputSiteId === null) {
      return {
        inputSiteId: props.siteId,
        inputPhysicalSite: props.physicalSite,
        inputPatientIdRangeStart: props.patientStartRange,
        inputPatientIdRangeEnd: props.patientEndRange,
        inputReason: '',
        inputUsername: window.sessionHandler.loggedInUser.userName,
        inputPassword: '',
      }
    }
     
    return null
  }

  render() {
    const { inputSiteId, inputPhysicalSite, inputPatientIdRangeStart, inputPatientIdRangeEnd, inputReason, inputUsername, inputPassword } = this.state
    const { handleConfirmed, handleCanceled, error, loading } = this.props

    const showRangeError = !isNil(inputPatientIdRangeStart) && !isEmpty(inputPatientIdRangeStart) && !isNil(inputPatientIdRangeEnd) && !isEmpty(inputPatientIdRangeEnd) && Number(inputPatientIdRangeStart) >= Number(inputPatientIdRangeEnd)

    return (
      <div>
        { error && this._renderError(error) }
        <MainInput
          value={ inputSiteId }
          name="inputSiteId"
          label="Enter Site ID"
          inputType="posNumber"
          onInputChanged={ this._handleInputChanged }
          returnFullInputEvent={ true }
          id="input-site-id" />
        <MainInput
          value={ inputPhysicalSite }
          name="inputPhysicalSite"
          maxLength={ 250 }
          label="Physical Site"
          onInputChanged={ this._handleInputChanged }
          returnFullInputEvent={ true }
          id="input-physical-site" />
        <MainInputGroup label="Enter patient ID range">
          <MainInput
            value={ inputPatientIdRangeStart }
            name="inputPatientIdRangeStart"
            label="From"
            inputType="posNumber"
            onInputChanged={ this._handleInputChanged }
            returnFullInputEvent={ true }
            id="input-site-patient-range-start" />
          <MainInput
            value={ inputPatientIdRangeEnd }
            name="inputPatientIdRangeEnd"
            label="To"
            inputType="posNumber"
            onInputChanged={ this._handleInputChanged }
            returnFullInputEvent={ true }
            id="input-site-patient-range-end" />
        </MainInputGroup>
        { showRangeError && this._renderError({ message: `To value needs to be higher than ${inputPatientIdRangeStart}` }) }
        <MainTextArea
          value={ inputReason }
          name="inputReason"
          label="Describe the reason for changing the site info"
          maxLength={ 250 }
          textAreaStyle="data-clarification-form_textarea"
          onInputChanged={ this._handleInputChanged }
          returnFullInputEvent={ true }
        />
        <MainInput
          value={ inputUsername }
          name="inputUsername"
          label="Username"
          inputType="text"
          onInputChanged={ this._handleInputChanged }
          returnFullInputEvent={ true }
          id="input-username"
        />
        <MainInput
          value={ inputPassword }
          name="inputPassword"
          label="Password"
          inputType="password"
          onInputChanged={ this._handleInputChanged }
          returnFullInputEvent={ true }
          id="input-password"
        />
        <ConfirmationButtons
          onCancel={ () => this._onCancel(handleCanceled) }
          onConfirm={ () => this._onConfirm(handleConfirmed, this.state) }
          cancelDisabled={ loading }
          confirmDisabled={ loading
            || isNilOrEmpty(inputSiteId)
            || showRangeError
            || isNilOrEmpty(inputPhysicalSite)
            || isNilOrEmpty(inputPatientIdRangeStart)
            || isNilOrEmpty(inputPatientIdRangeEnd)
            || isNilOrEmpty(inputReason)
            || isNilOrEmpty(inputUsername)
            || isNilOrEmpty(inputPassword) } />
      </div>
    )
  }

  _handleInputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _onCancel = (callback) => {
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }

  _onConfirm = (callback, state) => {
    try {
      callback({
        updatedSite: {
          id: state.inputSiteId,
          description: state.inputPhysicalSite.trim(),
          patientStartRange: state.inputPatientIdRangeStart,
          patientEndRange: state.inputPatientIdRangeEnd,
        },
        credentials: {
          userName: state.inputUsername,
          password: state.inputPassword,
        },
        reason: state.inputReason,
      })
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }
}

EditSiteFromCountryForm.propTypes = {
  siteId: PropTypes.string,
  patientStartRange: PropTypes.string,
  patientEndRange: PropTypes.string,
  physicalSite: PropTypes.string,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
}
EditSiteFromCountryForm.defaultProps = {
  siteId: '',
  patientStartRange: '',
  patientEndRange: '',
  physicalSite: '',
  error: null,
  loading: false,
}

export default EditSiteFromCountryForm
