import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cc from 'classcat'

import CollapsePickerItem from '../collapsePicker/CollapsePickerItem'

const StudyLink = (props) => {
  const { study, handleClick, studyClass } = props
  const { id, program } = study

  return (
    <CollapsePickerItem
    onItemClick={ handleClick(id) }
    itemClass={ cc([studyClass]) }>
      <Link
        to={ `/study/${id}` }
        tabIndex={ -1 }>
        { program && `${program} - ` }
        <strong>
          { id }
        </strong>
      </Link>
    </CollapsePickerItem>
  )
}

StudyLink.propTypes = {
  study: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  studyClass: PropTypes.string,
}

StudyLink.defaultProps = { studyClass: "" }

export default StudyLink
