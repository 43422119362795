import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'

import LoadingBox from '../loading/LoadingBox'
import ErrorHandler from '../error/ErrorHandler'

import MainButton from '../buttons/MainButton'
import MainButtonGroup from '../buttons/MainButtonGroup'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class LoadingModal extends Component {
  render() {
    const { error, loading, loadingHint, closeLoadingModal, retryAction } = this.props

    return (
      <div>
        { loading && this._renderLoadingHint(loadingHint) }
        { error && this._renderError(error) }
        { !loading && error && this._renderCancelOrRetryButtons(retryAction, closeLoadingModal, loading) }
      </div>
    )
  }

  _renderLoadingHint = text => (
    <LoadingBox
      size={ 16 }
      message={ text } />
  )

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _renderCancelOrRetryButtons(retryAction, closeLoadingModal, loading) {
    if (is(Function, retryAction)) {
      return (
        <ConfirmationButtons
          onCancel={ this._onCancel(closeLoadingModal) }
          onConfirm={ this._onRetry(retryAction) }
          confirmLabel="Retry"
          cancelDisabled={ loading }
          confirmDisabled={ loading } />
      )
    }
    return (
      <MainButtonGroup>
        <MainButton
          buttonClass="blue"
          label="CLOSE"
          handleClick={ closeLoadingModal }
          id="button-close" />
      </MainButtonGroup>
    )
  }

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onRetry = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

LoadingModal.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  loadingHint: PropTypes.string.isRequired,
  closeLoadingModal: PropTypes.func.isRequired,
  retryAction: PropTypes.func.isRequired,
}

LoadingModal.defaultProps = { error: null }

export default LoadingModal
