import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HintLabel from '../HintLabel'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class ConfirmForm extends Component {
  render() {
    const { handleCanceled, handleConfirmed, error, hintText, detailedHintText, loading } = this.props

    return (
      <div>
        { error && this._renderError(error) }
        <HintLabel size={ 16 }>
          { hintText }
          <strong>{ detailedHintText }</strong>
        </HintLabel>
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed) }
          cancelDisabled={ loading }
          confirmDisabled={ loading } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

ConfirmForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  error: PropTypes.object,
  hintText: PropTypes.string,
  detailedHintText: PropTypes.string,
  loading: PropTypes.bool.isRequired,
}

ConfirmForm.defaultProps = {
  error: null,
  hintText: "",
  detailedHintText: "",
}

export default ConfirmForm
