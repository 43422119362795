import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { is, isNil, isEmpty, uniqBy, prop, equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

import HintLabel from '../HintLabel'
import MainInput from '../inputs/MainInput'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class EditStudyForm extends Component {
  constructor(props) {
    super(props)

    const { study } = this.props

    this.state = {
      disableConfirm: true,
      inputStudyName: (study && study.id) || '',
      inputProgramName: (study && study.program) || '',
      selectProgramName: null,
      programOptions: null,
      errorDuplicateProgramName: null,
    }
  }

  componentDidMount() {
    const { studies } = this.props
    this._setProgramOptions(studies)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { studies } = this.props
    const nextStudies = nextProps.studies
    const receivedNewStudies = !equals(studies, nextStudies)
    if (receivedNewStudies) {
      this._setProgramOptions(nextStudies)
    }
  }

  // eslint-disable-next-line react/sort-comp
  _setProgramOptions(studies) {
    const filteredStudies = is(Array, studies) && !isEmpty(studies) && uniqBy(prop('program'), studies)
    const programOptions = is(Array, filteredStudies) && !isEmpty(filteredStudies) ? filteredStudies.map(item => ({
      value: item.program,
      label: item.program,
    })) : null

    this.setState(() => ({ programOptions }))
  }

  render() {
    const { handleCanceled, handleConfirmed, error, loading } = this.props
    const { inputProgramName, inputStudyName, selectProgramName, programOptions, errorDuplicateProgramName, disableConfirm } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <MainInput
          value={ inputProgramName }
          label="Enter new program name"
          onInputChanged={ this._programNameChanged }
          maxLength={ 32 }
          inputType="id"
          id="input-program-name" />
        { errorDuplicateProgramName && this._renderError(errorDuplicateProgramName) }
        <HintLabel
          size={ 16 }
          hintClass="black u-margin--vertical">
          OR
        </HintLabel>
        <Select
          className="portal-select-input u-margin--zero"
          name="program-select"
          options={ programOptions }
          placeholder="Select program from list"
          value={ selectProgramName }
          onChange={ this._selectedProgramNameChanged }
          id="dropdown-program-name" />
        <MainInput
          value={ inputStudyName }
          label="Enter new study name"
          onInputChanged={ this._studyNameChanged }
          maxLength={ 16 }
          inputType="id"
          id="input-study-name"
          onKeyPress={ this._handleKeyPress(handleConfirmed, inputStudyName, inputProgramName, selectProgramName, loading, disableConfirm, errorDuplicateProgramName) } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, inputStudyName, inputProgramName, selectProgramName) }
          cancelDisabled={ loading }
          confirmDisabled={ this._isConfirmDisabled(inputStudyName, inputProgramName, selectProgramName, loading, disableConfirm, errorDuplicateProgramName) } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _programNameChanged = (inputProgramName) => {
    this._checkDuplicateProgramName(inputProgramName)
    this.setState({
      inputProgramName,
      selectProgramName: '',
      disableConfirm: false,
    })
  }

  _checkDuplicateProgramName(programName) {
    const { studies } = this.props
    // check if program name already exists
    const programFound = is(Array, studies) && !isEmpty(studies) && studies.find(study => study.program.toLowerCase() === programName.toLowerCase())
    if (programFound) {
      this.setState({ errorDuplicateProgramName: { message: 'This program already exist. Please enter a different one or select the matching program from the list below.' } })
    }
    if (!programFound) {
      this.setState({ errorDuplicateProgramName: null })
    }
  }

  _selectedProgramNameChanged = (selectProgramName) => {
    this.setState({
      selectProgramName,
      inputProgramName: '',
      errorDuplicateProgramName: null,
      disableConfirm: false,
    })
  }

  _studyNameChanged = (inputStudyName) => {
    this.setState({
      inputStudyName,
      disableConfirm: false,
    })
  }

  _onCancel = cb => () => {
    try {
      cb()
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }


  _onConfirm = (cb, inputStudyName, inputProgramName, selectProgramName) => () => {
    try {
      cb({
        id: inputStudyName,
        program: inputProgramName || (selectProgramName && selectProgramName.value),
      })
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }

  _handleKeyPress = (handleConfirmed, inputStudyName, inputProgramName, selectProgramName, loading, disableConfirm, errorDuplicateProgramName) => (e) => {
    if (e.charCode === 13) { // if key is 'enter'
      if (!this._isConfirmDisabled(inputStudyName, inputProgramName, selectProgramName, loading, disableConfirm, errorDuplicateProgramName)) {
        this._onConfirm(handleConfirmed, inputStudyName, inputProgramName, selectProgramName)()
      }
    }
  }

  _isConfirmDisabled = (inputStudyName, inputProgramName, selectProgramName, loading, disableConfirm, errorDuplicateProgramName) => loading
    || disableConfirm
    || !isNil(errorDuplicateProgramName)
    || (isNilOrEmpty(inputProgramName)
      && isNilOrEmpty(selectProgramName))
    || isNilOrEmpty(inputStudyName)
}

EditStudyForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  study: PropTypes.object,
  error: PropTypes.object,
  studies: PropTypes.array,
  loading: PropTypes.bool.isRequired,
}

EditStudyForm.defaultProps = {
  study: null,
  error: null,
  studies: [],
}

export default EditStudyForm
