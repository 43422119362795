import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateTime from 'react-datetime'
import { equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import moment from 'moment'

import HintLabel from '../HintLabel'
import ErrorHandler from '../error/ErrorHandler'
import MainTextArea from '../inputs/MainTextArea'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class EditMilestoneForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdate: false,
      inputDescription: '',
      inputPlannedOn: '',
    }
  }

  componentDidMount() {
    const { milestone } = this.props
    if (!isNilOrEmpty(milestone)) {
      this.setState(() => ({
        isUpdate: true,
        inputPlannedOn: moment.utc(milestone.plannedOn),
        inputDescription: milestone.description,
      }))
    }
  }


  render() {
    const { handleCanceled, handleConfirmed, error, loading, milestone } = this.props
    const { inputPlannedOn, inputDescription, isUpdate } = this.state

    return (
      <div>
        { error && this._renderError(error) }
        <HintLabel
          size={ 16 }
          hintClass="u-margin--vertical">
          Scheduled date
        </HintLabel>
        <DateTime
          value={ inputPlannedOn }
          dateFormat="DD-MMM-YYYY"
          className="modal-date-picker"
          timeFormat={ false }
          onBlur={ this._blur }
          onChange={ this._dateChanged }
          isValidDate={ this._isValidDate } />
        <MainTextArea
          label="Description"
          maxLength={ 64 }
          value={ inputDescription }
          onInputChanged={ this._descriptionChanged } />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, inputDescription, inputPlannedOn) }
          confirmLabel={ isUpdate ? "Edit milestone export" : "Add milestone export" }
          cancelDisabled={ loading }
          confirmDisabled={ loading || isNilOrEmpty(inputPlannedOn) || (isUpdate && !this._hasMilestoneChanged(milestone, inputDescription, inputPlannedOn)) } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _descriptionChanged = (newDescription) => {
    this.setState({ inputDescription: newDescription })
  }

  _dateChanged = (newDate) => {
    this.setState(() => ({ inputPlannedOn: newDate }))
  }

  _isValidDate = (current) => {
    const yesterday = DateTime.moment().subtract(1, 'day')
    return current.isAfter(yesterday)
  }

  _hasMilestoneChanged = (originalMilestone, inputDescription, inputPlannedOn) => !isNilOrEmpty(originalMilestone) && (!equals(inputDescription, originalMilestone.description) || !inputPlannedOn.isSame(moment.utc(originalMilestone.plannedOn), 'day'))

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (callback, newDescription, newPlannedOn) => () => {
    const prepareDate = newPlannedOn.hours(23).minutes(59).seconds(59)
    try {
      callback({
        description: newDescription,
        plannedOn: prepareDate.format("YYYY-MM-DD[T]HH:mm:ss.SSS"),
      })
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

EditMilestoneForm.propTypes = {
  milestone: PropTypes.object,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

EditMilestoneForm.defaultProps = {
  milestone: null,
  error: null,
}

export default EditMilestoneForm
