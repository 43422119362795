import React from 'react'
import PropTypes from 'prop-types'

const TabBarItem = (props) => {
  const { children, itemClass } = props
  return (
    <div className={ ['tabbar__item'].concat(itemClass || []).join(' ') }>
      { children }
    </div>
  )
}

TabBarItem.propTypes = {
  children: PropTypes.node,
  itemClass: PropTypes.string,
}

TabBarItem.defaultProps = {
  children: null,
  itemClass: "",
}

export default TabBarItem
