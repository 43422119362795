import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'

import Dropdown from '../inputs/Dropdown'

class PlanningTableRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visitOptions: [],
      startVisitAsOption: null,
      endVisitAsOption: null,
    }
  }

  componentDidMount() {
    const { visits, startVisit, endVisit } = this.props
    this._createOptionsAndStartEndVisit(visits, startVisit, endVisit)
  }

  componentDidUpdate(prevProps) {
    const { visits, startVisit, endVisit } = this.props
    if (!equals(startVisit, prevProps.startVisit) || !equals(endVisit, prevProps.endVisit) || !equals(visits, prevProps.visits)) {
      this._createOptionsAndStartEndVisit(visits, startVisit, endVisit)
    }
  }

  render() {
    const { title, onPlanningRowChanged, isDisabled, endVisit } = this.props
    const { visitOptions, startVisitAsOption, endVisitAsOption } = this.state
    return (
      <tr>
        <td>
          { title }
        </td>
        <td>
          { this._renderDropdownOrLabel(isDisabled, "Select start visit", startVisitAsOption, visitOptions, onPlanningRowChanged, true) }
        </td>
        { endVisit
          ? (
            <td>
              { this._renderDropdownOrLabel(isDisabled, "Select end visit", endVisitAsOption, visitOptions, onPlanningRowChanged, false) }
            </td>
          ) : <td />}
      </tr>
    )
  }

  _createOptionsAndStartEndVisit = (visits, startVisit, endVisit) => {
    const visitOptions = visits.map(this._createVisitOption)
    const startVisitAsOption = visitOptions.find(visit => visit.value === startVisit)
    const endVisitAsOption = visitOptions.find(visit => visit.value === endVisit)
    this.setState({
      visitOptions,
      startVisitAsOption,
      endVisitAsOption,
    })
  }


  _createVisitOption = visit => ({
    value: visit.reference,
    label: visit.value,
  })

  _renderDropdownOrLabel(renderLabel, placeholder, selectedOption, options, callback, isStartVisit) {
    if (!renderLabel) {
      const idOfDropdown = isStartVisit ? "dropdown-study-planning-start" : "dropdown-study-planning-end"
      return (
        <Dropdown
          placeholder={ placeholder }
          selectedOption={ selectedOption }
          options={ options }
          onSelect={ this._onVisitChange(isStartVisit, callback) }
          id={ idOfDropdown } />
      )
    }
    return selectedOption && selectedOption.value
  }

  _onVisitChange = (isStartVisit, onPlanningRowChanged) => (newVisit) => {
    onPlanningRowChanged(isStartVisit, newVisit.value)
  }
}

PlanningTableRow.propTypes = {
  visits: PropTypes.array.isRequired,
  startVisit: PropTypes.string,
  endVisit: PropTypes.string,
  title: PropTypes.string.isRequired,
  onPlanningRowChanged: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}

PlanningTableRow.defaultProps = {
  isDisabled: false,
  endVisit: "",
  startVisit: "",
}

export default PlanningTableRow
