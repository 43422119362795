import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // upload global configuration
  uploadGlobalConfiguration: ['newGlobalConfiguration'],
  uploadGlobalConfigurationSuccess: null,
  uploadGlobalConfigurationFailure: ['error'],
  resetUploadGlobalConfigurationError: null,
  // Download global configuration
  downloadGlobalConfiguration: null,
  downloadGlobalConfigurationSuccess: null,
  downloadGlobalConfigurationFailure: ['error'],
  resetDownloadGlobalConfigurationError: null,
})

export const SettingsTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // upload global config
  newGlobalConfiguration: null,
  uploadGlobalConfigurationError: null,
  busyUploadingGlobalConfiguration: false,
  // download global configuration
  downloadGlobalConfigurationError: null,
  busyDownloadingGlobalConfiguration: false,
})

/* ------------- Reducers ------------- */

// upload global configuration actions
export const uploadGlobalConfiguration = (state, { newGlobalConfiguration }) => state.merge({
  busyUploadingGlobalConfiguration: true,
  newGlobalConfiguration,
  uploadGlobalConfigurationError: null,
})
export const uploadGlobalConfigurationSuccess = state => state.merge({
  busyUploadingGlobalConfiguration: false,
  newGlobalConfiguration: null,
})
export const uploadGlobalConfigurationFailure = (state, { error }) => state.merge({
  uploadGlobalConfigurationError: error,
  busyUploadingGlobalConfiguration: false,
})
export const resetUploadGlobalConfigurationError = state => state.merge({ uploadGlobalConfigurationError: null })
// download global configuration
export const downloadGlobalConfiguration = state => state.merge({
  busyDownloadingGlobalConfiguration: true,
  downloadGlobalConfigurationError: null,
})
export const downloadGlobalConfigurationSuccess = state => state.merge({ busyDownloadingGlobalConfiguration: false })
export const downloadStudyConfigurationFailure = (state, { error }) => state.merge({
  busyDownloadingGlobalConfiguration: false,
  downloadGlobalConfigurationError: error,
})
export const resetDownloadGlobalConfigurationError = state => state.merge({ downloadGlobalConfigurationError: null })
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // upload global configuration
  [Types.UPLOAD_GLOBAL_CONFIGURATION]: uploadGlobalConfiguration,
  [Types.UPLOAD_GLOBAL_CONFIGURATION_SUCCESS]: uploadGlobalConfigurationSuccess,
  [Types.UPLOAD_GLOBAL_CONFIGURATION_FAILURE]: uploadGlobalConfigurationFailure,
  [Types.RESET_UPLOAD_GLOBAL_CONFIGURATION_ERROR]: resetUploadGlobalConfigurationError,
  // // download global configuration
  [Types.DOWNLOAD_GLOBAL_CONFIGURATION]: downloadGlobalConfiguration,
  [Types.DOWNLOAD_GLOBAL_CONFIGURATION_SUCCESS]: downloadGlobalConfigurationSuccess,
  [Types.DOWNLOAD_GLOBAL_CONFIGURATION_FAILURE]: downloadStudyConfigurationFailure,
  [Types.RESET_DOWNLOAD_GLOBAL_CONFIGURATION_ERROR]: resetDownloadGlobalConfigurationError,
})
