import { call, put } from 'redux-saga/effects'
import fileDownload from 'js-file-download'
import SettingsActions from '../redux/SettingsRedux'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'


export function* uploadGlobalConfiguration(api, action) {
  const { newGlobalConfiguration } = action
  const formData = new FormData()
  formData.append("file", newGlobalConfiguration, newGlobalConfiguration.name)

  const { ok, data } = yield call(api.put, queries.UploadGlobalConfiguration(), formData, { headers: { "Content-Type": "multipart/form-data" } })
  
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(SettingsActions.uploadGlobalConfigurationSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Upload global configuration failed`)
    yield put(SettingsActions.uploadGlobalConfigurationFailure(errorObject))
  }
}

export function* downloadGlobalConfiguration(api) {
  const { ok, data } = yield call(api.get, queries.DownloadGlobalConfiguration(), {}, {
    headers: { Accept: 'application/zip' },
    responseType: 'blob',
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(SettingsActions.downloadGlobalConfigurationSuccess())
    fileDownload(embeddedData, `Global configuration.zip`)
  } else {
    const errorObject = addTitleToErrorObject(data, `Download global configuration failed`)
    yield put(SettingsActions.downloadGlobalConfigurationFailure(errorObject))
  }
}
