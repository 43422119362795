import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HintLabel from '../HintLabel'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'
import LoadingBox from '../loading/LoadingBox'

class DownloadGlobalConfigurationModal extends Component {
  render() {
    const { error, handleCanceled, handleConfirmed, loading } = this.props

    return (
      <div>
        {error && this._renderError(error)}
        {loading && this._renderLoadingBox()}
        {!loading && this._renderHintLabel()}
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed) }
          cancelDisabled={ loading }
          confirmDisabled={ loading } />
      </div>
    )
  }

  _renderLoadingBox = () => (
    <LoadingBox
      size={ 16 }
      hintClass="u-margin--vertical"
      message="Downloading global configuration"
    />
  )

  _renderHintLabel = () => (
    <div>
      <HintLabel
        size={ 16 }
        hintClass="u-margin--top">
        Please confirm you want to download the global configuration.
      </HintLabel>
    </div>
  )

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _onCancel = cb => () => {
    try {
      cb()
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = cb => () => {
    try {
      cb()
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }
}

DownloadGlobalConfigurationModal.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
}

DownloadGlobalConfigurationModal.defaultProps = { error: null }

export default DownloadGlobalConfigurationModal
