import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNilOrEmpty } from 'ramdasauce'

import MainTextArea from '../inputs/MainTextArea'
import ErrorHandler from '../error/ErrorHandler'
import ConfirmationButtons from '../buttons/ConfirmationButtons'

class ConfirmForm extends Component {
  constructor(props) {
    super(props)
    this.state = { inputReason: '' }
  }

  render() {
    const { inputReason } = this.state
    const { handleCanceled, handleConfirmed, error, loading, label } = this.props
    return (
      <div>
        { error && this._renderError(error) }
        <MainTextArea
          value={ inputReason }
          name="inputReason"
          label={ label }
          maxLength={ 250 }
          onInputChanged={ this._handleInputChanged }
          returnFullInputEvent={ true }
        />
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed, inputReason) }
          cancelDisabled={ loading }
          confirmDisabled={ loading || isNilOrEmpty(inputReason) } />
      </div>
    )
  }

  _handleInputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _onCancel = callback => () => {
    try {
      callback()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = (callback, inputReason) => () => {
    try {
      callback(inputReason)
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

ConfirmForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string,
}

ConfirmForm.defaultProps = {
  error: null,
  label: "",
}

export default ConfirmForm
