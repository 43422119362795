import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MainButton from '../buttons/MainButton'
import InvestigatorListTable from '../tables/InvestigatorListTable'
import { hasApplicationRole } from '../../utils/RoleHelper'

class CountryCardInvestigators extends Component {
  render() {
    const {
      investigators, handleOpenAddInvestigatorModal, handleOpenDeleteInvestigatorModal, handleOpenUpdateInvestigatorModal,
      isStudyArchived,
    } = this.props

    const hasInvestigators = investigators && investigators.length > 0
    const isApplicationRoleUser = hasApplicationRole()
    return (
      <div>
        { hasInvestigators && this._renderInvestigatorListTable(investigators, handleOpenUpdateInvestigatorModal, handleOpenDeleteInvestigatorModal, isApplicationRoleUser, isStudyArchived) }
        { !hasInvestigators && this._renderNoInvestigatorsDefined() }
        <MainButton
          buttonClass="inverted-blue u-margin--top"
          label="Add Investigator"
          icon={ { name: 'plus' } }
          isDisabled={ isApplicationRoleUser && isStudyArchived }
          handleClick={ handleOpenAddInvestigatorModal }
          id="button-add-investigator" />
      </div>
    )
  }

  _renderNoInvestigatorsDefined = () => (
    <div className="country-card--empty">
      No investigators defined.
    </div>
  )

  _renderInvestigatorListTable = (investigators, handleOpenUpdateInvestigatorModal, handleOpenDeleteInvestigatorModal, isApplicationRoleUser, isStudyArchived) => (
    <InvestigatorListTable
      investigators={ investigators }
      hasApplicationRole={ isApplicationRoleUser }
      isStudyArchived={ isStudyArchived }
      handleOpenUpdateInvestigatorModal={ handleOpenUpdateInvestigatorModal }
      handleOpenDeleteInvestigatorModal={ handleOpenDeleteInvestigatorModal } />
  )
}

CountryCardInvestigators.propTypes = {
  investigators: PropTypes.array,
  handleOpenDeleteInvestigatorModal: PropTypes.func.isRequired,
  handleOpenAddInvestigatorModal: PropTypes.func.isRequired,
  handleOpenUpdateInvestigatorModal: PropTypes.func.isRequired,
  isStudyArchived: PropTypes.bool,
}

CountryCardInvestigators.defaultProps = {
  investigators: [],
  isStudyArchived: false,
}

export default CountryCardInvestigators
