const queries = {
  activateScreening: 'activateScreening',
  allPdrs: 'allPDR',
  associatedEvents: 'associated-events',
  associatedQuestionnaires: 'associated-questionnaires',
  countries: 'countries',
  configure: 'configure',
  events: 'events',
  fastTrack: 'fasttrack',
  investigators: 'members',
  milestones: 'milestones',
  permissions: 'permissions',
  questionnaires: 'questionnaires',
  sites: 'sites',
  studies: 'studies',
  virtualVisitsToggle: 'toggle-virtual-visits',
  ActivateScreening(_studyId) {
    return `${this.studies}/${_studyId}/${this.activateScreening}`
  },
  ArchiveStudy(_studyId) {
    return `${this.studies}/${_studyId}/archive`
  },
  AssociatedEvent(_studyId, _associatedEventId) {
    return `${this.studies}/${_studyId}/${this.associatedEvents}/${_associatedEventId}`
  },
  AssociatedQuestionnaire(_studyId, _associatedQuestionnaireId) {
    return `${this.studies}/${_studyId}/${this.associatedQuestionnaires}/${_associatedQuestionnaireId}`
  },
  Countries() {
    return `${this.countries}`
  },
  CountriesByStudyId(_studyId) {
    return `${this.studies}/${_studyId}/${this.countries}`
  },
  DownloadStudy(_studyId) {
    return `${this.studies}/${_studyId}/${this.configure}`
  },
  Event(_studyId, _eventId) {
    return `${this.studies}/${_studyId}/${this.events}/${_eventId}`
  },
  ExportAllPdrs(_studyId) {
    return `${this.studies}/${_studyId}/${this.allPdrs}`
  },
  FastTrack(_studyId) {
    return `${this.studies}/${_studyId}/${this.fastTrack}`
  },
  Investigators() {
    return `${this.investigators}`
  },
  InvestigatorByStudyIdAndMemberId(_studyId, _memberId) {
    return `${this.studies}/${_studyId}/${this.investigators}/${_memberId}`
  },
  InvestigatorsByStudyId(_studyId) {
    return `${this.studies}/${_studyId}/${this.permissions}`
  },
  Milestone(_studyId, _milestoneId) {
    return `${this.studies}/${_studyId}/${this.milestones}/${_milestoneId}`
  },
  CancelMilestone(_studyId, _milestoneId) {
    return `${this.studies}/${_studyId}/${this.milestones}/${_milestoneId}/cancel`
  },
  MilestonesByStudyId(_studyId) {
    return `${this.studies}/${_studyId}/${this.milestones}`
  },
  Milestones() {
    return `${this.studies}/${this.milestones}`
  },
  PublishStudy(_studyId) {
    return `${this.studies}/${_studyId}/publish`
  },
  PermissionsByStudyIdCountryIdAndMemberId(_studyId, _countryId, _memberId) {
    return `${this.studies}/${_studyId}/${this.permissions}/${_countryId}/${_memberId}`
  },
  Questionnaire(_studyId, _questionnaireId) {
    return `${this.studies}/${_studyId}/${this.questionnaires}/${_questionnaireId}`
  },
  SiteByStudyIdCountryIdAndSiteId(_studyId, _countryId, _siteId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}/${_siteId}`
  },
  Sites(_studyId, _countryId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}`
  },
  Site(_studyId, _countryId, _siteId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.sites}/${_siteId}`
  },
  Studies() {
    return `${this.studies}`
  },
  Study(_studyId) {
    return `${this.studies}/${_studyId}`
  },
  ToggleVirtualVisits(_studyId, _countryId) {
    return `${this.studies}/${_studyId}/${this.countries}/${_countryId}/${this.virtualVisitsToggle}`
  },
  UploadStudy(_studyId) {
    return `${this.studies}/${_studyId}/${this.configure}`
  },
  UploadGlobalConfiguration() {
    return `${this.configure}`
  },
  DownloadGlobalConfiguration() {
    return `${this.configure}`
  },
}

export default queries
