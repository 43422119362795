import React from 'react'
import PropTypes from 'prop-types'

const StudyStatusTitle = (props) => {
  const { status } = props
  let studyStatus = ''

  if (status) {
    switch (status) {
      case 1:
        studyStatus = 'test'
        break
      case 2:
        studyStatus = 'live'
        break
      case 3:
        studyStatus = 'DB Lock'
        break
      default:
        studyStatus = ''
        break
    }
    return (
      <span className="navbar-header__status">{ studyStatus }</span>
    )
  }
  return null
}

StudyStatusTitle.propTypes = { status: PropTypes.number }

StudyStatusTitle.defaultProps = { status: 0 }

export default StudyStatusTitle
