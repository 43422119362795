import moment from 'moment'
import { prop, ascend, descend, is, sort } from 'ramda'

export function sortById(arrayToSort) {
  return is(Array, arrayToSort) ? sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10), arrayToSort) : arrayToSort
}

export function sortByStringId(arrayToSort) {
  return is(Array, arrayToSort) ? arrayToSort.sort().sort((a, b) => {
    if (a.length > b.length) {
      return 1
    }
    if (a.length < b.length) {
      return -1
    }
    return 0
  }) : arrayToSort
}

export function sortByStringProp(arrayToSort, property) {
  return is(Array, arrayToSort) ? arrayToSort.sort((a, b) => {
    if (is(String, prop(property, a))) {
      return prop(property, a) > prop(property, b) ? 1 : -1
    }
    return 0
  }).sort((a, b) => {
    if (is(String, prop(property, a))) {
      if (prop(property, a).length > prop(property, b).length) {
        return 1
      }
      if (prop(property, a).length < prop(property, b).length) {
        return -1
      }
    }
    return 0
  }) : arrayToSort
}

export const sortByDateProperty = (propName, inAscend = true) => (left, right) => (inAscend ? moment.utc(prop(propName, left)).diff(moment.utc(prop(propName, right))) : moment.utc(prop(propName, right)).diff(moment.utc(prop(propName, left))))

export const sortInAscendingOrDescending = (sortingCallback, inAscend = true) => (inAscend ? ascend(sortingCallback) : descend(sortingCallback))
