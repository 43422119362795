import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNil, isEmpty } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import Dropzone from 'react-dropzone'
import cc from 'classcat'

import withRouter from '../../components/wrapperReactRouterDom'
import StudyActions from '../../redux/StudyRedux'
import SiteActions from '../../redux/SiteRedux'
import CountryActions from '../../redux/CountryRedux'

import Icon from '../../components/Icon'
import HintLabel from '../../components/HintLabel'
import CountryCard from '../../components/countryCard/CountryCard'
import ErrorHandler from '../../components/error/ErrorHandler'

import { isStudyArchived, canStudyHaveVirtualVisits } from '../../utils/StudyHelper'
import { hasApplicationRole } from '../../utils/RoleHelper'

import MainButton from '../../components/buttons/MainButton'
import MainButtonGroup from '../../components/buttons/MainButtonGroup'

import BaseModal from '../../components/modals/BaseModal'
import ConfirmForm from '../../components/modals/ConfirmForm'
import EditSiteFromCountryForm from '../../components/modals/EditSiteFromCountryForm'
import LoadingModal from '../../components/modals/LoadingModal'
import AddSiteToCountryForm from '../../components/modals/AddSiteToCountryForm'

export class StudyTabConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteToDeleteFromState: null,
      siteToUpdateFromState: null,
      countryIdOfSiteToAdd: null,
      newStudyConfigFromState: null,
      dropzoneIsHovered: false,
      showAddSiteModal: false,
      showEditSiteModal: false,
      showDeleteSiteModal: false,
      showUploadConfigModal: false,
      showDownloadConfigModal: false,
      countryLocationsOfSiteToAdd: null,
      showExportAllPdrsModal: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { newSite, siteToDelete, updatedSite, newStudyConfig, busyDownloadingConfig, busyExportingAllPdrs } = this.props
    const {
      resetUploadStudyConfigurationError, resetEditSiteError, resetDownloadStudyConfigurationError, resetAddSiteError,
      resetDeleteSiteError, resetExportAllPdrsError,
    } = this.props

    // ADD SITE
    const newSiteHasBeenAdded = newSite && !nextProps.newSite && !nextProps.addSiteError
    if (newSiteHasBeenAdded) {
      this._closeAddSiteModal(resetAddSiteError)()
    }
    // UPDATE SITE
    const siteHasBeenUpdated = updatedSite && !nextProps.updatedSite && !nextProps.editSiteError
    if (siteHasBeenUpdated) {
      this._closeEditSiteModal(resetEditSiteError)()
    }
    // DELETE SITE
    const siteHasBeenDeleted = siteToDelete && !nextProps.siteToDelete && !nextProps.deleteSiteError
    if (siteHasBeenDeleted) {
      this._closeDeleteSiteModal(resetDeleteSiteError)()
    }
    // UPLOAD NEW CONFIG
    const newConfigHasBeenUploaded = newStudyConfig && !nextProps.newStudyConfig && !nextProps.uploadStudyConfigurationError
    if (newConfigHasBeenUploaded) {
      this._closeUploadConfigModal(resetUploadStudyConfigurationError)()
    }
    // DOWNLOAD CONFIG
    const configHasBeenDownloaded = busyDownloadingConfig && !nextProps.busyDownloadingConfig && !nextProps.downloadStudyConfigurationError
    if (configHasBeenDownloaded) {
      this._closeDownloadConfigModal(resetDownloadStudyConfigurationError)()
    }
    // EXPORT ALL PDRS
    const allPdrsHaveBeenExported = busyExportingAllPdrs && !nextProps.busyExportingAllPdrs && !nextProps.exportAllPdrsError
    if (allPdrsHaveBeenExported) {
      this._closeExportAllPdrs(resetExportAllPdrsError)
    }
  }

  render() {
    const {
      sites,
      study,
      countries, fetchCountriesByStudyIdError, toggleVirtualVisitsForCountry, busyTogglingVirtualVisits, toggleVirtualVisitsError, resetToggleVirtualVisitsForCountryError,
      addSite, addSiteError, busyAddingSite, resetAddSiteError,
      editSite, editSiteError, busyEditingSite, resetEditSiteError,
      deleteSite, deleteSiteError, resetDeleteSiteError, busyDeletingSite,
      uploadStudyConfiguration, uploadStudyConfigurationError, busyUploadingConfig, resetUploadStudyConfigurationError,
      downloadStudyConfiguration, downloadStudyConfigurationError, busyDownloadingConfig, resetDownloadStudyConfigurationError,
      exportAllPdrs, exportAllPdrsError, busyExportingAllPdrs, resetExportAllPdrsError,
    } = this.props

    const {
      siteToUpdateFromState, siteToDeleteFromState, countryIdOfSiteToAdd, newStudyConfigFromState, dropzoneIsHovered, countryLocationsOfSiteToAdd,
      showAddSiteModal, showEditSiteModal, showDeleteSiteModal, showUploadConfigModal, showDownloadConfigModal, showExportAllPdrsModal,
    } = this.state

    const hasError = !isNil(fetchCountriesByStudyIdError)
    const hasExportError = !isNil(exportAllPdrsError)
    const hasStudy = !isNil(study) && !isEmpty(study)
    const isArchivedStudy = hasStudy && isStudyArchived(study.workflow)
    const canHaveVirtualVisits = canStudyHaveVirtualVisits(study)
    const hasCountries = !isNil(countries) && !isEmpty(countries)

    return (
      <div>
        { hasError && this._renderError(fetchCountriesByStudyIdError) }
        { !hasError && hasStudy && hasCountries && this._renderConfigurationUploaded(
          countries, sites, study.id, downloadStudyConfiguration, dropzoneIsHovered, isArchivedStudy, canHaveVirtualVisits, toggleVirtualVisitsForCountry,
          busyTogglingVirtualVisits, toggleVirtualVisitsError, resetToggleVirtualVisitsForCountryError, exportAllPdrs,
        )
        }
        { !hasError && hasStudy && !hasCountries && this._renderNoConfigurationUploaded(dropzoneIsHovered) }
        { showAddSiteModal && this._renderAddSiteModal(study.id, countryIdOfSiteToAdd, addSite, resetAddSiteError, addSiteError, busyAddingSite, countryLocationsOfSiteToAdd) }
        { showEditSiteModal && this._renderEditSiteModal(study.id, siteToUpdateFromState, editSite, resetEditSiteError, editSiteError, busyEditingSite) }
        { showDeleteSiteModal && this._renderDeleteSiteModal(study.id, siteToDeleteFromState, deleteSite, resetDeleteSiteError, deleteSiteError, busyDeletingSite) }
        { showUploadConfigModal && this._renderUploadConfigModal(study.id, newStudyConfigFromState, uploadStudyConfiguration, uploadStudyConfigurationError, busyUploadingConfig, resetUploadStudyConfigurationError) }
        { showDownloadConfigModal && this._renderDownloadConfigModal(study.id, downloadStudyConfiguration, downloadStudyConfigurationError, busyDownloadingConfig, resetDownloadStudyConfigurationError) }
        { (showExportAllPdrsModal || busyExportingAllPdrs || hasExportError) && this._renderExportAllPdrsModal(study.id, exportAllPdrs, exportAllPdrsError, busyExportingAllPdrs, resetExportAllPdrsError) }
      </div>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderNoConfigurationUploaded(dropzoneIsHovered) {
    return (
      <div>
        <HintLabel
          size={ 18 }
          hintClass="col-6">
          No study configuration uploaded.
        </HintLabel>
        <MainButtonGroup>
          <Dropzone
            onDrop={ this._onDrop }
            accept={ { 'application/zip': ['.zip'] } }
            multiple={ false }
            onDragEnter={ this._onDragEnter }
            onDragLeave={ this._onDragLeave }>
            {({ getRootProps, getInputProps }) => (
              <section className={ dropzoneIsHovered ? "main-button blue col-6 upload-dropzone hover-active" : "main-button blue col-6 upload-dropzone" }>
                <div { ...getRootProps() }>
                  <input { ...getInputProps() } />

                  <Icon name="upload" size={ 25 } />
                  <span>Upload or drop new study configuration </span>
                </div>
              </section>
            )}  
          </Dropzone>
        </MainButtonGroup>
      </div>
    )
  }

  _renderConfigurationUploaded(countries, sites, studyId, downloadStudyConfiguration, dropzoneIsHovered, isArchivedStudy, canHaveVirtualVisits,
    toggleVirtualVisitsForCountry, busyTogglingVirtualVisits, toggleVirtualVisitsError, resetToggleVirtualVisitsForCountryError, exportAllPdrs) {
    return (
      <div>
        { !hasApplicationRole() && this._renderBulkExportPDR(studyId, exportAllPdrs) }
        { this._renderCountryCards(countries, sites, isArchivedStudy, canHaveVirtualVisits, toggleVirtualVisitsForCountry, studyId,
          busyTogglingVirtualVisits, toggleVirtualVisitsError, resetToggleVirtualVisitsForCountryError) }
        { this._renderDownloadUploadButtons(studyId, downloadStudyConfiguration, dropzoneIsHovered, isArchivedStudy) }
      </div>
    )
  }


  _renderCountryCards(countries, sites, isArchivedStudy, canHaveVirtualVisits, toggleVirtualVisitsForCountry, studyId, busyTogglingVirtualVisits, toggleVirtualVisitsError, resetToggleVirtualVisitsForCountryError) {
    return (
      <div>
        { countries.map(country => (
          <CountryCard
            key={ country.alpha2Code }
            cardType="sites"
            country={ country }
            isStudyArchived={ isArchivedStudy }
            canHaveVirtualVisits={ canHaveVirtualVisits }
            busyTogglingVirtualVisits={ busyTogglingVirtualVisits }
            resetVirtualVisitsError={ () => this._resetToggleVirtualVisitsForCountryError(resetToggleVirtualVisitsForCountryError) }
            toggleVirtualVisitsError={ toggleVirtualVisitsError }
            handleConfirmVirtualVisits={ (reason, virtualVisitsEnabled) => this._confirmVirtualVisits(toggleVirtualVisitsForCountry, studyId, country.id, reason, virtualVisitsEnabled) }
            sites={ this._filterSitesByCountry(sites, country.alpha2Code) }
            handleOpenAddSiteModal={ this._openAddSiteModal(country.alpha2Code, country.locations) }
            handleOpenEditSiteModal={ this._openEditSiteModal }
            handleOpenDeleteSiteModal={ this._openDeleteSiteModal } />
        )) }
      </div>
    )
  }

  _resetToggleVirtualVisitsForCountryError = (resetToggleVirtualVisitsForCountryError) => {
    resetToggleVirtualVisitsForCountryError()
  }

  _confirmVirtualVisits = (toggleVirtualVisitsForCountry, studyId, countryId, reason, virtualVisitsEnabled) => {
    toggleVirtualVisitsForCountry(studyId, countryId, reason, virtualVisitsEnabled)
  }

  // export all pdrs
  _renderBulkExportPDR = (studyId, exportAllPdrs) => (
    <MainButtonGroup buttonGroupClass="align-right u-margin--zero">
      <MainButton
        buttonClass="blue u-margin--bottom"
        handleClick={ this._downloadAllPdrs(studyId, exportAllPdrs) }
        label="Export all PDRs"
        icon={ { name: "download", size: 25 } }
        id="button-export-all-pdrs" />
    </MainButtonGroup>
  )

  _renderExportAllPdrsModal = (studyId, exportAllPdrs, exportAllPdrsError, busyExportingAllPdrs, resetExportAllPdrsError) => (
    <BaseModal
      title="Export all PDRs"
      handleClose={ () => this._closeExportAllPdrs(resetExportAllPdrsError) }
      forceInteraction={ busyExportingAllPdrs }>
      <LoadingModal
        loading={ busyExportingAllPdrs }
        loadingHint={ `Exporting all PDRs for ${studyId}, this might take a few minutes` }
        error={ exportAllPdrsError }
        closeLoadingModal={ () => this._closeExportAllPdrs(resetExportAllPdrsError) }
        retryAction={ this._downloadAllPdrs(studyId, exportAllPdrs) } />
    </BaseModal>
  )

  _closeExportAllPdrs = (resetExportAllPdrsError) => {
    resetExportAllPdrsError()
    this.setState({ showExportAllPdrsModal: false })
  }

  _downloadAllPdrs = (studyId, exportAllPdrs) => () => {
    this._openExportAllPdrs()
    exportAllPdrs(studyId)
  }

  _openExportAllPdrs = () => {
    this.setState({ showExportAllPdrsModal: true })
  }

  // DOWNLOAD STUDY CONFIGURATION
  _renderDownloadUploadButtons(studyId, downloadStudyConfiguration, dropzoneIsHovered, isArchivedStudy) {
    const icons = {
      downloadIcon: {
        name: 'download',
        size: 25,
      },
      uploadIcon: {
        name: 'upload',
        size: 25,
      },
    }

    const dropzoneClasses = cc(["main-button bordeaux col-6 upload-dropzone", { "hover-active": dropzoneIsHovered }, { disabled: isArchivedStudy }])

    return (
      <MainButtonGroup buttonGroupClass="upload-download-buttons">
        <MainButton
          buttonClass="green col-6"
          handleClick={ this._startDownloadProcedure(studyId, downloadStudyConfiguration) }
          label="Download study configuration"
          icon={ icons.downloadIcon }
          id="button-download-config" />
        <Dropzone
          onDrop={ this._onDrop }
          accept={ { 'application/zip': ['.zip'] } }
          multiple={ false }
          disabled={ isArchivedStudy }
          className={ dropzoneClasses }
          onDragEnter={ this._onDragEnter }
          onDragLeave={ this._onDragLeave }
          id="button-upload-config">
            {({ getRootProps, getInputProps }) => (
              <section className={ dropzoneClasses }>
                <div { ...getRootProps() }>
                  <input { ...getInputProps() } />

                  <Icon name="upload" size={ 25 } />
                  <span>Upload or drop new study configuration </span>
                </div>
              </section>
            )}  
        </Dropzone>
      </MainButtonGroup>
    )
  }

  _startDownloadProcedure = (studyId, downloadStudyConfiguration) => () => {
    this._openDownloadConfigModal()
    downloadStudyConfiguration(studyId)
  }

  _renderDownloadConfigModal(studyId, downloadStudyConfiguration, downloadStudyConfigurationError, busyDownloadingConfig, resetDownloadStudyConfigurationError) {
    return (
      <BaseModal
        title="Download study configuration"
        handleClose={ this._closeDownloadConfigModal(resetDownloadStudyConfigurationError) }
        forceInteraction={ busyDownloadingConfig }>
        <LoadingModal
          loading={ busyDownloadingConfig }
          loadingHint={ `Downloading study configuration for ${studyId}, this might take a few minutes` }
          error={ downloadStudyConfigurationError }
          closeLoadingModal={ this._closeDownloadConfigModal(resetDownloadStudyConfigurationError) }
          retryAction={ this._startDownloadProcedure(studyId, downloadStudyConfiguration) } />
      </BaseModal>
    )
  }

  _openDownloadConfigModal = () => {
    this.setState(() => ({ showDownloadConfigModal: true }))
  }

  _closeDownloadConfigModal = resetDownloadStudyConfigurationError => () => {
    this.setState(() => ({ showDownloadConfigModal: false }))
    resetDownloadStudyConfigurationError()
  }

  _onDrop = (files) => {
    this.setState(() => ({ dropzoneIsHovered: false }))
    if (!isNilOrEmpty(files)) {
      this._openUploadConfigModal(files[0])
    }
  }

  _onDragEnter = () => {
    this.setState(() => ({ dropzoneIsHovered: true }))
  }

  _onDragLeave = () => {
    this.setState(() => ({ dropzoneIsHovered: false }))
  }

  // UPLOAD NEW CONFIGURATION CONFIRM MODAL
  _renderUploadConfigModal(studyId, newStudyConfig, uploadStudyConfiguration, uploadStudyConfigurationError, busyUploadingConfig, resetUploadStudyConfigurationError) {
    return (
      <BaseModal
        title="Confirm upload of new configuration"
        handleClose={ this._closeUploadConfigModal(resetUploadStudyConfigurationError) }
        forceInteraction={ busyUploadingConfig }>
        <ConfirmForm
          error={ uploadStudyConfigurationError }
          loading={ busyUploadingConfig }
          handleCanceled={ this._closeUploadConfigModal(resetUploadStudyConfigurationError) }
          handleConfirmed={ this._uploadConfigConfirmed(studyId, newStudyConfig, uploadStudyConfiguration) }
          hintText={ `Please confirm a new configuration (${newStudyConfig.name}) will be uploaded for the following study: ` }
          detailedHintText={ studyId } />
      </BaseModal>
    )
  }

  _openUploadConfigModal = (newStudyConfig) => {
    this.setState(() => ({
      showUploadConfigModal: true,
      newStudyConfigFromState: newStudyConfig,
    }))
  }

  _closeUploadConfigModal = resetUploadStudyConfigurationError => () => {
    this.setState(() => ({ showUploadConfigModal: false }))
    resetUploadStudyConfigurationError()
  }

  _uploadConfigConfirmed = (studyId, newStudyConfig, uploadStudyConfiguration) => () => {
    uploadStudyConfiguration(studyId, newStudyConfig)
  }

  // ADD SITE
  _renderAddSiteModal(studyId, countryId, addSite, resetAddSiteError, addSiteError, busyAddingSite, availableLocations) {
    return (
      <BaseModal
        title="Configure Site Id / Patient Id Range"
        handleClose={ this._closeAddSiteModal(resetAddSiteError) }
        forceInteraction={ busyAddingSite }>
        <AddSiteToCountryForm
          error={ addSiteError }
          loading={ busyAddingSite }
          availableLocations={ availableLocations }
          handleCanceled={ this._closeAddSiteModal(resetAddSiteError) }
          handleConfirmed={ this._addSiteConfirmed(addSite, studyId, countryId) } />
      </BaseModal>
    )
  }

  _openAddSiteModal = (countryIdOfSiteToAdd, countryLocationsOfSiteToAdd) => () => {
    this.setState({
      showAddSiteModal: true,
      countryIdOfSiteToAdd,
      countryLocationsOfSiteToAdd,
    })
  }

  _closeAddSiteModal = resetAddSiteError => () => {
    this.setState(() => ({
      showAddSiteModal: false,
      countryIdOfSiteToAdd: null,
      countryLocationsOfSiteToAdd: null,
    }))
    resetAddSiteError()
  }

  _addSiteConfirmed = (addSite, studyId, countryId) => (newSite) => {
    addSite(studyId, countryId, newSite)
  }

  // EDIT SITE
  _renderEditSiteModal(studyId, siteToUpdateFromState, editSite, resetEditSiteError, editSiteError, busyEditingSite) {
    const { id, patientStartRange, patientEndRange, description, country } = siteToUpdateFromState
    return (
      <BaseModal
        title="Edit Site"
        handleClose={ this._closeEditSiteModal(resetEditSiteError) }
        forceInteraction={ busyEditingSite }>
        <EditSiteFromCountryForm
          siteId={ id }
          patientStartRange={ patientStartRange }
          patientEndRange={ patientEndRange }
          physicalSite={ description }
          error={ editSiteError }
          handleConfirmed={ this._editSiteConfirmed(editSite, studyId, country, id) }
          handleCanceled={ this._closeEditSiteModal(resetEditSiteError) }
        />
      </BaseModal>
    )
  }

  _openEditSiteModal = site => () => {
    this.setState(() => ({
      showEditSiteModal: true,
      siteToUpdateFromState: site,
    }))
  }

  _closeEditSiteModal = resetEditSiteError => () => {
    this.setState(() => ({
      showEditSiteModal: false,
      siteToUpdateFromState: null,
    }))
    resetEditSiteError()
  }

  _editSiteConfirmed = (editSite, studyId, countryId, siteIdToUpdate) => (callback) => {
    editSite(studyId, countryId, siteIdToUpdate, callback.updatedSite, callback.credentials, callback.reason)
  }

  // DELETE SITE
  _renderDeleteSiteModal(studyId, siteToDeleteFromState, deleteSite, resetDeleteSiteError, deleteSiteError, busyDeletingSite) {
    const { id, country, description } = siteToDeleteFromState
    return (
      <BaseModal
        title="Confirm removal of site"
        handleClose={ this._closeDeleteSiteModal(resetDeleteSiteError) }
        forceInteraction={ busyDeletingSite }>
        <ConfirmForm
          error={ deleteSiteError }
          loading={ busyDeletingSite }
          handleCanceled={ this._closeDeleteSiteModal(resetDeleteSiteError) }
          handleConfirmed={ this._deleteSiteConfirmed(studyId, country, id, deleteSite) }
          hintText="Please confirm deleting the following site: "
          detailedHintText={ `${id} ${description ? ' - ' : ''} ${description || ''}` } />
      </BaseModal>
    )
  }

  _openDeleteSiteModal = site => () => {
    this.setState(() => ({
      showDeleteSiteModal: true,
      siteToDeleteFromState: site,
    }))
  }

  _closeDeleteSiteModal = resetDeleteSiteError => () => {
    this.setState(() => ({ showDeleteSiteModal: false }))
    resetDeleteSiteError()
  }

  _deleteSiteConfirmed = (studyId, countryId, siteId, deleteSite) => () => {
    deleteSite(studyId, countryId, siteId)
  }


  _filterSitesByCountry = (sites, countryId) => (sites && sites.length > 0 && sites.filter(site => site.country.toLowerCase() === countryId.toLowerCase())) || []
}

StudyTabConfig.propTypes = {
  // study
  study: PropTypes.object,
  newStudyConfig: PropTypes.object,
  // countries
  countries: PropTypes.array,
  fetchCountriesByStudyIdError: PropTypes.object,
  // sites
  sites: PropTypes.array,
  // add site
  newSite: PropTypes.object,
  addSite: PropTypes.func.isRequired,
  addSiteError: PropTypes.object,
  resetAddSiteError: PropTypes.func.isRequired,
  busyAddingSite: PropTypes.bool.isRequired,
  // edit site
  updatedSite: PropTypes.object,
  editSite: PropTypes.func.isRequired,
  editSiteError: PropTypes.object,
  resetEditSiteError: PropTypes.func.isRequired,
  busyEditingSite: PropTypes.bool.isRequired,
  // delete site
  siteToDelete: PropTypes.string,
  deleteSite: PropTypes.func.isRequired,
  deleteSiteError: PropTypes.object,
  resetDeleteSiteError: PropTypes.func.isRequired,
  busyDeletingSite: PropTypes.bool.isRequired,
  // upload config
  uploadStudyConfiguration: PropTypes.func.isRequired,
  uploadStudyConfigurationError: PropTypes.object,
  resetUploadStudyConfigurationError: PropTypes.func.isRequired,
  busyUploadingConfig: PropTypes.bool.isRequired,
  // download config
  downloadStudyConfiguration: PropTypes.func.isRequired,
  downloadStudyConfigurationError: PropTypes.object,
  resetDownloadStudyConfigurationError: PropTypes.func.isRequired,
  busyDownloadingConfig: PropTypes.bool.isRequired,
  // toggle virtual visits
  toggleVirtualVisitsForCountry: PropTypes.func.isRequired,
  busyTogglingVirtualVisits: PropTypes.bool.isRequired,
  toggleVirtualVisitsError: PropTypes.object,
  resetToggleVirtualVisitsForCountryError: PropTypes.func.isRequired,
  // export all pdrs
  exportAllPdrs: PropTypes.func.isRequired,
  busyExportingAllPdrs: PropTypes.bool.isRequired,
  exportAllPdrsError: PropTypes.object,
  resetExportAllPdrsError: PropTypes.func.isRequired,
}

StudyTabConfig.defaultProps = {
  // study
  study: null,
  newStudyConfig: null,
  // countries
  countries: [],
  fetchCountriesByStudyIdError: null,
  // sites
  sites: [],
  // add site
  newSite: null,
  addSiteError: null,
  // edit site
  updatedSite: null,
  editSiteError: null,
  // delete site
  siteToDelete: "",
  deleteSiteError: null,
  // upload config
  uploadStudyConfigurationError: null,
  // download config
  downloadStudyConfigurationError: null,
  // toggle virtual visits
  toggleVirtualVisitsError: null,
  // export all pdrs
  exportAllPdrsError: null,
}

export const mapStateToProps = state => ({
  newStudyConfig: state.studies.newStudyConfig,
  sites: state.sites.siteList,
  // countries
  countries: state.countries.countryListByStudyId,
  fetchCountriesByStudyIdError: state.countries.fetchCountriesByStudyIdError,
  // toggle virtual visits
  busyTogglingVirtualVisits: state.countries.busyTogglingVirtualVisitsForCountry,
  toggleVirtualVisitsError: state.countries.toggleVirtualVisitsForCountryError,
  // study
  study: state.studies.study,
  // site
  newSite: state.sites.newSite,
  updatedSite: state.sites.updatedSite,
  siteToDelete: state.sites.siteToDelete,
  addSiteError: state.sites.addSiteError,
  editSiteError: state.sites.editSiteError,
  deleteSiteError: state.sites.deleteSiteError,
  busyAddingSite: state.sites.busyAddingSite,
  busyEditingSite: state.sites.busyEditingSite,
  busyDeletingSite: state.sites.busyDeletingSite,
  // upload config
  busyUploadingConfig: state.studies.busyUploadingConfig,
  uploadStudyConfigurationError: state.studies.uploadStudyConfigurationError,
  // download config
  busyDownloadingConfig: state.studies.busyDownloadingConfig,
  downloadStudyConfigurationError: state.studies.downloadStudyConfigurationError,
  // export all pdrs
  busyExportingAllPdrs: state.studies.busyExportingAllPdrs,
  exportAllPdrsError: state.studies.exportAllPdrsError,
})

export const mapDispatchToProps = dispatch => ({
  addSite: (studyId, countryId, site) => dispatch(SiteActions.addSite(studyId, countryId, site)),
  editSite: (studyId, countryId, siteIdToUpdate, site, credentials, reason) => dispatch(SiteActions.editSite(studyId, countryId, siteIdToUpdate, site, credentials, reason)),
  deleteSite: (studyId, countryId, siteId) => dispatch(SiteActions.deleteSite(studyId, countryId, siteId)),
  resetAddSiteError: () => dispatch(SiteActions.resetAddSiteError()),
  resetEditSiteError: () => dispatch(SiteActions.resetEditSiteError()),
  resetDeleteSiteError: () => dispatch(SiteActions.resetDeleteSiteError()),
  toggleVirtualVisitsForCountry: (studyId, countryId, reason, virtualVisitsEnabled) => dispatch(CountryActions.toggleVirtualVisitsForCountry(studyId, countryId, reason, virtualVisitsEnabled)),
  resetToggleVirtualVisitsForCountryError: () => dispatch(CountryActions.resetToggleVirtualVisitsForCountryError()),
  downloadStudyConfiguration: studyId => dispatch(StudyActions.downloadStudyConfiguration(studyId)),
  uploadStudyConfiguration: (studyId, newStudyConfig) => dispatch(StudyActions.uploadStudyConfiguration(studyId, newStudyConfig)),
  resetUploadStudyConfigurationError: () => dispatch(StudyActions.resetUploadStudyConfigurationError()),
  resetDownloadStudyConfigurationError: () => dispatch(StudyActions.resetDownloadStudyConfigurationError()),
  exportAllPdrs: studyId => dispatch(StudyActions.exportAllPdrs(studyId)),
  resetExportAllPdrsError: () => dispatch(StudyActions.resetExportAllPdrsError()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyTabConfig))
