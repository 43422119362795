import { call, put } from 'redux-saga/effects'

import InvestigatorActions from '../redux/InvestigatorRedux'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

export function* fetchInvestigators(api) {
  const { ok, data } = yield call(api.get, queries.Investigators())
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(InvestigatorActions.fetchInvestigatorsSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading all investigators failed`)
    yield put(InvestigatorActions.fetchInvestigatorsFailure(errorObject))
  }
}
export function* fetchInvestigatorsByStudy(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.InvestigatorsByStudyId(studyId))
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(InvestigatorActions.fetchInvestigatorsByStudySuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading investigators of study ${studyId} failed`)
    yield put(InvestigatorActions.fetchInvestigatorsByStudyFailure(errorObject))
  }
}

export function* addInvestigator(api, action) {
  const { studyId, countryId, newInvestigator } = action
  const { id, allSites, selectedSites } = newInvestigator
  const { ok, data } = yield call(api.put, queries.PermissionsByStudyIdCountryIdAndMemberId(studyId, countryId, id), {
    allSites,
    selectedSites,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(InvestigatorActions.addInvestigatorSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding investigator failed`)
    yield put(InvestigatorActions.addInvestigatorFailure(errorObject))
  }
}

export function* deleteInvestigator(api, action) {
  const { studyId, countryId, investigatorToDelete } = action
  const { id, allSites, selectedSites } = investigatorToDelete
  const { ok, data } = yield call(api.put, queries.PermissionsByStudyIdCountryIdAndMemberId(studyId, countryId, id), {
    allSites,
    selectedSites,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(InvestigatorActions.deleteInvestigatorSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Deleting investigator failed`)
    yield put(InvestigatorActions.deleteInvestigatorFailure(errorObject))
  }
}

export function* updateInvestigator(api, action) {
  const { studyId, countryId, investigatorToUpdate } = action
  const { id, allSites, selectedSites } = investigatorToUpdate
  const { ok, data } = yield call(api.put, queries.PermissionsByStudyIdCountryIdAndMemberId(studyId, countryId, id), {
    allSites,
    selectedSites,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(InvestigatorActions.updateInvestigatorSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Updating investigator failed`)
    yield put(InvestigatorActions.updateInvestigatorFailure(errorObject))
  }
}
