import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNilOrEmpty } from 'ramdasauce'

import MainButton from '../buttons/MainButton'
import PlanningTableSubRowAssociatedToVisit from './PlanningTableSubRowAssociatedToVisit'
import BaseModal from '../modals/BaseModal'
import AddItemToVisitForm from '../modals/AddItemToVisitForm'
import ConfirmForm from '../modals/ConfirmForm'
import { sortByStringId, sortByStringProp } from '../../utils/SortingHelper'

class PlanningTableRowAssociatedToVisit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      visitList: [],
      showAddVisitModal: false,
      showDeleteVisitModal: false,
      selectedVisits: [],
      selectedVisitToDelete: null,
      selectedReferenceToDeleteFrom: "",
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.associatedVisits !== state.selectedVisits) {
      return { selectedVisits: props.associatedVisits }
    }
    return null
  }

  componentDidMount() {
    const { associatedVisits, visits } = this.props
    this.setState(() => ({
      visitList: visits.map(visit => visit.reference),
      selectedVisits: associatedVisits,
    }))
  }

  render() {
    const { visitList, selectedVisits, showAddVisitModal, showDeleteVisitModal, selectedVisitToDelete, selectedReferenceToDeleteFrom } = this.state
    const { title, reference, onPlanningRowChanged, isDisabled } = this.props
    return (
      <>
        <tr>
          <td>{ title }</td>
          <td>
            <MainButton
              label="Add Visit"
              buttonClass="blue"
              isDisabled={ isDisabled }
              handleClick={ () => this._openAddVisitModal(visitList, selectedVisits) }
              icon={ { name: "plus" } }
              id="button-add-visit" />
          </td>
          <td>
            { showAddVisitModal && this._renderAddToVisitModal(title, reference, visitList, onPlanningRowChanged) }
            { showDeleteVisitModal && this._renderDeleteVisitModal(selectedVisitToDelete, selectedReferenceToDeleteFrom, onPlanningRowChanged, title) }
          </td>
        </tr>
        { !isNilOrEmpty(selectedVisits) && this._renderAssociatedTableSubRows(selectedVisits, reference, onPlanningRowChanged, title, isDisabled) }
      </>
    )
  }

  _openAddVisitModal = (visitList, selectedVisits) => {
    const newVisitList = visitList.filter(visit => !selectedVisits.some(selectedVisit => selectedVisit.value === visit))
    this.setState({
      showAddVisitModal: true,
      visitList: newVisitList,
    })
  }

  _closeAddVisitModal = () => {
    this.setState({ showAddVisitModal: false })
  }

  _onConfirm = (selectedVisits, onPlanningRowChanged, reference, value) => {
    const { selectedVisits: prevSelectedVisits } = this.state
    const { visitList } = this.state
    // sort the associated PDI's with the new visit added and filter available visit list
    const newSelectedVisitsState = sortByStringProp([...prevSelectedVisits, ...selectedVisits], "value")
    const newVisitList = visitList.filter(visit => !newSelectedVisitsState.some(selectedVisit => selectedVisit.value === visit))
    this.setState(() => ({
      selectedVisits: newSelectedVisitsState,
      visitList: newVisitList,
      showAddVisitModal: false,
    }))

    onPlanningRowChanged(newSelectedVisitsState, reference, value)
  }

  _renderAddToVisitModal = (title, reference, visits, onPlanningRowChanged) => (
    <BaseModal
      title="Add Visit"
      handleClose={ this._closeAddVisitModal }>
      <AddItemToVisitForm
        visitList={ visits }
        title={ title }
        handleCanceled={ this._closeAddVisitModal }
        handleConfirmed={ selectedVisits => this._onConfirm(selectedVisits, onPlanningRowChanged, reference, title) } />
    </BaseModal>
  )

  _renderDeleteVisitModal = (selectedVisit, reference, onPlanningRowChanged, value) => (
    <BaseModal
      title="Confirm removal of visit"
      handleClose={ this._closeDeleteVisitModal }>
      <ConfirmForm
        loading={ false }
        handleCanceled={ this._closeDeleteVisitModal }
        handleConfirmed={ () => this._confirmDeleteVisit(selectedVisit, reference, onPlanningRowChanged, value) }
        hintText="Please confirm deleting the following visit: "
        detailedHintText={ `${selectedVisit}` } />
    </BaseModal>
  )

  _openDeleteVisitModal = (selectedVisit, reference) => {
    this.setState({
      showDeleteVisitModal: true,
      selectedVisitToDelete: selectedVisit,
      selectedReferenceToDeleteFrom: reference,
    })
  }

  _closeDeleteVisitModal = () => {
    this.setState({ showDeleteVisitModal: false })
  }

  _confirmDeleteVisit = (selectedVisitValue, reference, onPlanningRowChanged, value) => {
    const { visitList, selectedVisits } = this.state
    // filter out the deleted associated visit and re-sort the available visit list
    const newSelectedVisits = selectedVisits.filter(selectedVisitFromArray => selectedVisitFromArray.value !== selectedVisitValue)
    this.setState(() => ({
      selectedVisits: newSelectedVisits,
      visitList: sortByStringId([...visitList, selectedVisitValue]),
      showDeleteVisitModal: false,
    }))
    onPlanningRowChanged(newSelectedVisits, reference, value)
  }

  _renderAssociatedTableSubRows = (selectedVisits, reference, onPlanningRowChanged, title, isDisabled) => selectedVisits
    .map(visit => (
      <PlanningTableSubRowAssociatedToVisit
        key={ visit.value }
        handleOffsetChanged={ associatedVisit => this._handleOffsetChanged(associatedVisit, selectedVisits, onPlanningRowChanged, reference, title) }
        isDisabled={ isDisabled }
        handleDelete={ value => this._openDeleteVisitModal(value, reference) }
        selectedVisit={ visit } />
    ))

  _handleOffsetChanged = (associatedVisit, selectedVisits, onPlanningRowChanged, reference, value) => {
    const newSelectedVisits = selectedVisits.map(obj => (obj.value === associatedVisit.value ? associatedVisit : obj))
    this.setState({ selectedVisits: newSelectedVisits })
    onPlanningRowChanged(newSelectedVisits, reference, value)
  }
}


PlanningTableRowAssociatedToVisit.propTypes = {
  title: PropTypes.string,
  visits: PropTypes.array,
  isDisabled: PropTypes.bool,
  reference: PropTypes.string.isRequired,
  associatedVisits: PropTypes.array,
  onPlanningRowChanged: PropTypes.func.isRequired,
}

PlanningTableRowAssociatedToVisit.defaultProps = {
  title: "",
  isDisabled: false,
  associatedVisits: [],
  visits: null,
}


export default PlanningTableRowAssociatedToVisit
