import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

import Icon from './Icon'

const ClickableIcon = (props) => {
  const { size, handleClick, id, disabled, ...rest } = props
  return (
    <div className={ cc([{ "icon-disabled-wrapper": disabled }]) }>
      <div
        id={ id }
        className={ cc(['icon--clickable', { disabled }]) }
        onClick={ handleClick }
        onKeyDown={ handleClick }
        style={ { width: `${size}px` } }
        role="button"
        tabIndex={ 0 }>
        <Icon
          size={ size }
          { ...rest } />
      </div>
    </div>
  )
}

ClickableIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  rotate: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
}

ClickableIcon.defaultProps = {
  size: 20,
  color: "",
  rotate: 0,
  style: null,
  disabled: false,
}

export default ClickableIcon
