import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // countries
  fetchCountries: null,
  fetchCountriesSuccess: ['countries'],
  fetchCountriesFailure: ['error'],
  // countries by study id
  fetchCountriesByStudyId: ['studyId'],
  fetchCountriesByStudyIdSuccess: ['countries'],
  fetchCountriesByStudyIdFailure: ['error'],
  resetCountriesByStudyId: null,
  // toggle virtual visits for country
  toggleVirtualVisitsForCountry: ['studyId', 'countryId', 'reason', 'virtualVisitsEnabled'],
  toggleVirtualVisitsForCountrySuccess: ['country'],
  toggleVirtualVisitsForCountryFailure: ['error'],
  resetToggleVirtualVisitsForCountryError: null,
})

export const CountryTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // countries
  countryList: [],
  busyFetchingCountries: false,
  fetchCountriesError: null,
  // countries by study
  countryListByStudyId: null,
  busyFetchingCountriesByStudyId: false,
  fetchCountriesByStudyIdError: null,
  // toggle virtual visits for country
  busyTogglingVirtualVisitsForCountry: false,
  toggleVirtualVisitsForCountryError: null,
})

/* ------------- Reducers ------------- */

// countries
export const fetchCountries = state => state.merge({
  busyFetchingCountries: true,
  countryList: [],
  fetchCountriesError: null,
})
export const fetchCountriesSuccess = (state, { countries }) => state.merge({
  busyFetchingCountries: false,
  countryList: countries,
})
export const fetchCountriesFailure = (state, { error }) => state.merge({
  busyFetchingCountries: false,
  fetchCountriesError: error,
})
// countries by study id
export const fetchCountriesByStudyId = state => state.merge({
  busyFetchingCountriesByStudyId: true,
  countryListByStudyId: null,
  fetchCountriesByStudyIdError: null,
})
export const fetchCountriesByStudyIdSuccess = (state, { countries }) => state.merge({
  busyFetchingCountriesByStudyId: false,
  countryListByStudyId: countries,
})
export const fetchCountriesByStudyIdFailure = (state, { error }) => state.merge({
  busyFetchingCountriesByStudyId: false,
  fetchCountriesByStudyIdError: error,
})
export const resetCountriesByStudyId = state => state.merge({ countryListByStudyId: null })
// toggle virtual visits for country
export const toggleVirtualVisitsForCountry = state => state.merge({
  busyTogglingVirtualVisitsForCountry: true,
  toggleVirtualVisitsForCountryError: null,
})
export const toggleVirtualVisitsForCountrySuccess = (state, { country }) => state.merge({
  countryListByStudyId: [...state.countryListByStudyId.map(item => (item.id === country.id ? country : item))],
  busyTogglingVirtualVisitsForCountry: false,
})
export const toggleVirtualVisitsForCountryFailure = (state, { error }) => state.merge({
  busyTogglingVirtualVisitsForCountry: false,
  toggleVirtualVisitsForCountryError: error,
})
export const resetToggleVirtualVisitsForCountryError = state => state.merge({ toggleVirtualVisitsForCountryError: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // countries
  [Types.FETCH_COUNTRIES]: fetchCountries,
  [Types.FETCH_COUNTRIES_SUCCESS]: fetchCountriesSuccess,
  [Types.FETCH_COUNTRIES_FAILURE]: fetchCountriesFailure,
  // countries by study id
  [Types.FETCH_COUNTRIES_BY_STUDY_ID]: fetchCountriesByStudyId,
  [Types.FETCH_COUNTRIES_BY_STUDY_ID_SUCCESS]: fetchCountriesByStudyIdSuccess,
  [Types.FETCH_COUNTRIES_BY_STUDY_ID_FAILURE]: fetchCountriesByStudyIdFailure,
  [Types.RESET_COUNTRIES_BY_STUDY_ID]: resetCountriesByStudyId,
  // toggle virtual visits for country
  [Types.TOGGLE_VIRTUAL_VISITS_FOR_COUNTRY]: toggleVirtualVisitsForCountry,
  [Types.TOGGLE_VIRTUAL_VISITS_FOR_COUNTRY_SUCCESS]: toggleVirtualVisitsForCountrySuccess,
  [Types.TOGGLE_VIRTUAL_VISITS_FOR_COUNTRY_FAILURE]: toggleVirtualVisitsForCountryFailure,
  [Types.RESET_TOGGLE_VIRTUAL_VISITS_FOR_COUNTRY_ERROR]: resetToggleVirtualVisitsForCountryError,
})
