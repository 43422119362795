import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { is, isEmpty, isNil } from 'ramda'

import ConfirmationButtons from '../buttons/ConfirmationButtons'

class AddItemToVisitForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visitOptions: null,
      disableConfirm: true,
      selectedVisits: null,
    }
  }

  componentDidMount() {
    const { visitList } = this.props
    if (is(Array, visitList) && !isEmpty(visitList)) {
      this._setVisitOptions(visitList)
    }
  }

  componentDidUpdate(prevProps) {
    const { visitList } = this.props
    if (prevProps.visitList !== visitList && is(Array, visitList) && !isEmpty(visitList)) {
      this._setVisitOptions(visitList)
    }
  }

  render() {
    const { visitOptions, selectedVisits, disableConfirm } = this.state
    const { title, handleCanceled, handleConfirmed } = this.props

    return (
      <div>
        { title }
        <Select
          className="portal-select-input"
          options={ visitOptions }
          onChange={ this._selectedVisitsChanged }
          value={ selectedVisits }
          placeholder="Select one or multiple visit(s).."
          isMulti={ true }
          id="dropdown-site" />
        <ConfirmationButtons
          onCancel={ handleCanceled }
          onConfirm={ this._onConfirm(handleConfirmed, selectedVisits) }
          confirmDisabled={ disableConfirm
            || isNil(selectedVisits) || isEmpty(selectedVisits) } />
      </div>
    )
  }

  _onConfirm = (handleConfirmed, selectedVisits) => () => {
    try {
      handleConfirmed(selectedVisits.map(selectedVisit => ({ value: selectedVisit.value, daysOffset: "0" })))
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _setVisitOptions = (visitList) => {
    const visitOptions = visitList.map(visitReference => ({
      value: visitReference,
      label: visitReference,
    }))
    this.setState({ visitOptions })
  }

  _selectedVisitsChanged = (selectedVisits) => {
    this.setState({
      selectedVisits,
      disableConfirm: false,
    })
  }
}

AddItemToVisitForm.propTypes = {
  title: PropTypes.string,
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  visitList: PropTypes.array,
}

AddItemToVisitForm.defaultProps = {
  title: "",
  visitList: [],
}

export default AddItemToVisitForm
