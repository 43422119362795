import { call, put } from 'redux-saga/effects'
import { is, isEmpty } from 'ramda'

import CountryActions from '../redux/CountryRedux'
import SiteActions from '../redux/SiteRedux'
import InvestigatorActions from '../redux/InvestigatorRedux'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

export function* fetchCountries(api) {
  const { ok, data } = yield call(api.get, queries.Countries())
  const countries = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(CountryActions.fetchCountriesSuccess(countries))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading countries failed`)
    yield put(CountryActions.fetchCountriesFailure(errorObject))
  }
}

export function* fetchCountriesByStudyId(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.CountriesByStudyId(studyId))
  const countries = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(CountryActions.fetchCountriesByStudyIdSuccess(countries))
    const countryIds = is(Array, countries) && !isEmpty(countries) && countries.map(country => country.alpha2Code)
    if (countryIds) {
      yield put(SiteActions.fetchSites(studyId, countryIds, true))
    }
    yield put(InvestigatorActions.fetchInvestigatorsByStudy(studyId))
    yield put(InvestigatorActions.fetchInvestigators())
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading countries failed`)
    yield put(CountryActions.fetchCountriesByStudyIdFailure(errorObject))
  }
}

export function* toggleVirtualVisitsForCountry(api, action) {
  const { studyId, countryId, virtualVisitsEnabled, reason } = action
  const { ok, data } = yield call(api.put, queries.ToggleVirtualVisits(studyId, countryId), {
    reason,
    virtualVisitsEnabled,
  })
  if (ok) {
    yield put(CountryActions.toggleVirtualVisitsForCountrySuccess(data))
  } else {
    const errorObject = addTitleToErrorObject(data, `${virtualVisitsEnabled ? 'Enabling' : 'Disabling'} virtual visits failed`)
    yield put(CountryActions.toggleVirtualVisitsForCountryFailure(errorObject))
  }
}
