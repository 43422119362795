import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { sortById } from '../utils/SortingHelper'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchSites: ['studyId', 'countryIds', 'includeDetails'],
  fetchSitesSuccess: ['sites'],
  fetchSitesFailure: ['error'],
  addSite: ['studyId', 'countryId', 'newSite'],
  addSiteSuccess: ['site'],
  addSiteFailure: ['error'],
  editSite: ['studyId', 'countryId', 'siteIdToUpdate', 'updatedSite', 'credentials', 'reason'],
  editSiteSuccess: ['site', 'siteIdToUpdate'],
  editSiteFailure: ['error'],
  deleteSite: ['studyId', 'countryId', 'siteId'],
  deleteSiteSuccess: ['siteId'],
  deleteSiteFailure: ['error'],
  resetAddSiteError: null,
  resetEditSiteError: null,
  resetDeleteSiteError: null,
})

export const SiteTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // sites
  siteList: [],
  busyFetchingSites: false,
  fetchSitesError: null,
  // add site
  newSite: null,
  addSiteError: null,
  busyAddingSite: false,
  // edit site
  updatedSite: null,
  editSiteError: null,
  busyEditingSite: false,
  // delete site
  siteToDelete: null,
  deleteSiteError: null,
  busyDeletingSite: false,
})

/* ------------- Reducers ------------- */
// sites
export const fetchSites = state => state.merge({
  busyFetchingSites: true,
  siteList: [],
  fetchSitesError: null,
})
export const fetchSitesFailure = (state, { error }) => state.merge({
  busyFetchingSites: false,
  fetchSitesError: error,
})
export const fetchSitesSuccess = (state, { sites }) => state.merge({
  busyFetchingSites: false,
  siteList: sites,
})
// add site
export const addSite = (state, { newSite }) => state.merge({
  busyAddingSite: true,
  newSite,
  addSiteError: null,
})
export const addSiteFailure = (state, { error }) => state.merge({
  busyAddingSite: false,
  addSiteError: error,
})
export const addSiteSuccess = (state, { site }) => state.merge({
  busyAddingSite: false,
  newSite: null,
  siteList: sortById([...state.siteList, site]),
})
export const resetAddSiteError = state => state.merge({ addSiteError: null })
// edit site
export const editSite = (state, { updatedSite }) => state.merge({
  busyEditingSite: true,
  updatedSite,
  editSiteError: null,
})

export const editSiteSuccess = (state, { site, siteIdToUpdate }) => state.merge({
  busyEditingSite: false,
  updatedSite: null,
  siteList: sortById([
    ...state.siteList.slice(0, state.siteList.findIndex(e => e.id === siteIdToUpdate)), // everything before current site
    site,
    ...state.siteList.slice(state.siteList.findIndex(e => e.id === siteIdToUpdate) + 1), // everything after current site
  ]),
})

export const editSiteFailure = (state, { error }) => state.merge({
  busyEditingSite: false,
  editSiteError: error,
})
export const resetEditSiteError = state => state.merge({ editSiteError: null })
// delete site
export const deleteSite = (state, { siteId }) => state.merge({
  busyDeletingSite: true,
  siteToDelete: siteId,
  deleteSiteError: null,
})
export const deleteSiteSuccess = (state, { siteId }) => state.merge({
  busyDeletingSite: false,
  siteToDelete: null,
  siteList: [
    ...state.siteList.slice(0, state.siteList.findIndex(e => e.id === siteId)), // everything before current site
    ...state.siteList.slice(state.siteList.findIndex(e => e.id === siteId) + 1), // everything after current site
  ],
})
export const deleteSiteFailure = (state, { error }) => state.merge({
  busyDeletingSite: false,
  deleteSiteError: error,
})
export const resetDeleteSiteError = state => state.merge({ deleteSiteError: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // sites
  [Types.FETCH_SITES]: fetchSites,
  [Types.FETCH_SITES_SUCCESS]: fetchSitesSuccess,
  [Types.FETCH_SITES_FAILURE]: fetchSitesFailure,
  // add site
  [Types.ADD_SITE]: addSite,
  [Types.ADD_SITE_SUCCESS]: addSiteSuccess,
  [Types.ADD_SITE_FAILURE]: addSiteFailure,
  [Types.RESET_ADD_SITE_ERROR]: resetAddSiteError,
  // edit site
  [Types.EDIT_SITE]: editSite,
  [Types.EDIT_SITE_SUCCESS]: editSiteSuccess,
  [Types.EDIT_SITE_FAILURE]: editSiteFailure,
  [Types.RESET_EDIT_SITE_ERROR]: resetEditSiteError,
  // delete site
  [Types.DELETE_SITE]: deleteSite,
  [Types.DELETE_SITE_SUCCESS]: deleteSiteSuccess,
  [Types.DELETE_SITE_FAILURE]: deleteSiteFailure,
  [Types.RESET_DELETE_SITE_ERROR]: resetDeleteSiteError,
})
