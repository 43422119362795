import { call, put, all } from 'redux-saga/effects'
import { isEmpty } from 'ramda'
import SiteActions from '../redux/SiteRedux'
import InvestigatorActions from '../redux/InvestigatorRedux'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

export function* fetchSites(api, action) {
  const { studyId, countryIds, includeDetails } = action

  const result = yield all(countryIds.map(countryId => call(api.get, queries.Sites(studyId, countryId), { includeDetails })))

  const ok = result.every(item => item.ok === true)

  const data = result.map(item => item.data)

  const filteredData = data.filter(item => item !== undefined)
  if (ok) {
    const embeddedData = []
    if (!isEmpty(filteredData)) {
      data.forEach(item => {
        if (item._embedded && item._embedded.item) {
          item._embedded.item.map(subItem => embeddedData.push(subItem))
        }
      })
    }
    yield put(SiteActions.fetchSitesSuccess(embeddedData))
  } else {
    const errorObject = result.map((item, index) => addTitleToErrorObject(item.data, index === 0 ? `Loading sites failed` : ''))
    yield put(SiteActions.fetchSitesFailure(errorObject))
  }
}

export function* addSite(api, action) {
  const { studyId, countryId, newSite } = action
  const { id, patientStartRange, patientEndRange, description, location } = newSite
  const { ok, data } = yield call(api.post, queries.Sites(studyId, countryId), {
    id,
    patientStartRange,
    patientEndRange,
    description,
    location,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(SiteActions.addSiteSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding site failed`)
    yield put(SiteActions.addSiteFailure(errorObject))
  }
}

export function* editSite(api, action) {
  const { studyId, countryId, siteIdToUpdate, updatedSite, credentials, reason } = action
  const { id, patientStartRange, patientEndRange, description } = updatedSite
  const { ok, data } = yield call(api.put, queries.Site(studyId, countryId, siteIdToUpdate), {
    id,
    patientStartRange,
    patientEndRange,
    description,
    userName: credentials.userName,
    password: credentials.password,
    reason,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(SiteActions.editSiteSuccess(embeddedData, siteIdToUpdate))
    yield put(InvestigatorActions.fetchInvestigatorsByStudy(studyId))
  } else {
    const errorObject = { hasConfigurableContent: true, ...data }
    yield put(SiteActions.editSiteFailure(errorObject))
  }
}

export function* deleteSite(api, action) {
  const { studyId, countryId, siteId } = action
  const { ok, data } = yield call(api.delete, queries.Site(studyId, countryId, siteId))
  if (ok) {
    yield put(SiteActions.deleteSiteSuccess(siteId))
  } else {
    const errorObject = addTitleToErrorObject(data, `Deleting site failed`)
    yield put(SiteActions.deleteSiteFailure(errorObject))
  }
}
