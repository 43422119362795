import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

const CollapsePickerSelecter = (props) => {
  const { title, shouldRotate, ...rest } = props
  const rotateDegree = shouldRotate ? 0 : 270
  return (
    <div { ...rest }>
      <span className="collapsepicker-selecter-title">
        { title }
      </span>
      <Icon
        name="chevron-down"
        rotate={ rotateDegree }
        color="white" />
    </div>
  )
}

CollapsePickerSelecter.propTypes = {
  title: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  shouldRotate: PropTypes.bool,
}

CollapsePickerSelecter.defaultProps = { shouldRotate: false }

export default CollapsePickerSelecter
