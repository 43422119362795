import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

const MilestoneTableHeaders = (props) => {
  const { canEditAndCancelMilestones, sortMilestonesBy, sortAscending, setSorting } = props

  const defaultSortingClassNames = [
    "is-sortable",
    {
      ascending: sortAscending,
      descending: !sortAscending,
    },
  ]
  const idClassNames = cc([
    "id",
    { "is-sorted": sortMilestonesBy === "id" },
    ...defaultSortingClassNames,
  ])

  const plannedOnClassNames = cc([
    "date",
    "align-right",
    { "is-sorted": sortMilestonesBy === "plannedOn" },
    ...defaultSortingClassNames,
  ])
  return (
    <thead>
      <tr>
        <th
          className={ idClassNames }
          onClick={ setSorting(sortMilestonesBy, sortAscending, "id") }>
          ID
        </th>
        <th
          className={ plannedOnClassNames }
          onClick={ setSorting(sortMilestonesBy, sortAscending, "plannedOn") }>
          Scheduled Date
        </th>
        <th className="study align-right">
          Study
        </th>
        <th className="program align-right">
          Program
        </th>
        <th className="description">
          Description
        </th>
        <th className="status align-right">
          Status
        </th>
        { canEditAndCancelMilestones && <th /> }
        { canEditAndCancelMilestones && <th /> }
      </tr>
    </thead>
  )
}

MilestoneTableHeaders.propTypes = {
  canEditAndCancelMilestones: PropTypes.bool,
  sortAscending: PropTypes.bool,
  sortMilestonesBy: PropTypes.string,
  setSorting: PropTypes.func.isRequired,
}

MilestoneTableHeaders.defaultProps = {
  canEditAndCancelMilestones: false,
  sortAscending: false,
  sortMilestonesBy: "",
}

export default MilestoneTableHeaders
