import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MainButton from '../buttons/MainButton'
import SiteListTable from '../tables/SiteListTable'

class CountryCardSites extends Component {
  render() {
    const { sites, isStudyArchived, canHaveVirtualVisits, handleOpenAddSiteModal, handleOpenEditSiteModal, handleOpenDeleteSiteModal } = this.props

    const hasSites = sites && sites.length > 0

    return (
      <div>
        { hasSites && this._renderSiteListTable(sites, isStudyArchived, canHaveVirtualVisits, handleOpenEditSiteModal, handleOpenDeleteSiteModal) }
        { !hasSites && this._renderNoSitesDefined() }
        <MainButton
          buttonClass="inverted-blue u-margin--top"
          label="Add Site"
          icon={ { name: 'plus' } }
          handleClick={ handleOpenAddSiteModal }
          isDisabled={ isStudyArchived }
          id="button-add-site" />
      </div>
    )
  }

  _renderNoSitesDefined = () => (
    <div className="country-card--empty">
      No sites defined.
    </div>
  )

  _renderSiteListTable = (sites, isStudyArchived, canHaveVirtualVisits, handleOpenEditSiteModal, handleOpenDeleteSiteModal) => (
    <SiteListTable
      sites={ sites }
      isStudyArchived={ isStudyArchived }
      canHaveVirtualVisits={ canHaveVirtualVisits }
      handleOpenEditSiteModal={ handleOpenEditSiteModal }
      handleOpenDeleteSiteModal={ handleOpenDeleteSiteModal } />
  )
}

CountryCardSites.propTypes = {
  sites: PropTypes.array,
  isStudyArchived: PropTypes.bool,
  canHaveVirtualVisits: PropTypes.bool,
  handleOpenAddSiteModal: PropTypes.func.isRequired,
  handleOpenEditSiteModal: PropTypes.func.isRequired,
  handleOpenDeleteSiteModal: PropTypes.func.isRequired,
}

CountryCardSites.defaultProps = {
  sites: [],
  isStudyArchived: false,
  canHaveVirtualVisits: false,
}

export default CountryCardSites
