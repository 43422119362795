import React from 'react'
import PropTypes from 'prop-types'

const MainContainer = (props) => {
  const { children } = props
  return (
    <div className="main-container">
      { children }
    </div>
  )
}

MainContainer.propTypes = { children: PropTypes.node.isRequired }

export default MainContainer
