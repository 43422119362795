import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

const LogoutButton = (props) => {
  const { handleLogout } = props
  return (
    <button
      type="button"
      className="logout-button"
      onClick={ handleLogout }>
      <Icon
        name="logout"
        size={ 16 }
        rotate={ 270 } />
      <p className="u-margin--zero">
        Log out
      </p>
    </button>
  )
}

LogoutButton.propTypes = { handleLogout: PropTypes.func.isRequired }

export default LogoutButton
