import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty, isNil } from 'ramda'

import withRouter from '../../components/wrapperReactRouterDom'
import TabContainer from '../layout/TabContainer'
import HintLabel from '../../components/HintLabel'
import ErrorHandler from '../../components/error/ErrorHandler'

class StartContainer extends Component {
  render() {
    const { fetchStudiesError, busyFetchingStudies } = this.props

    const hasError = !isNil(fetchStudiesError) && !isEmpty(fetchStudiesError)
    return (
      <TabContainer>
        { hasError && this._renderError(fetchStudiesError) }
        { !busyFetchingStudies && !hasError && this._renderSelectOrCreateStudyText() }
      </TabContainer>
    )
  }

  _renderError = error => <ErrorHandler error={ error } />

  _renderSelectOrCreateStudyText = () => (
    <HintLabel>
      Please select a study or create a new study
    </HintLabel>
  )
}


StartContainer.propTypes = {
  busyFetchingStudies: PropTypes.bool.isRequired,
  fetchStudiesError: PropTypes.object,
}

StartContainer.defaultProps = { fetchStudiesError: null }

const mapStateToProps = state => ({
  fetchStudiesError: state.studies.fetchStudiesError,
  busyFetchingStudies: state.studies.busyFetchingStudies,
})

export default withRouter(connect(mapStateToProps, null)(StartContainer))
