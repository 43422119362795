import React from 'react'
import PropTypes from 'prop-types'
import { is, isNil, isEmpty } from 'ramda'

import LoadingBox from './LoadingBox'

const LoadingHandler = (props) => {
  const { loading } = props
  let loadingObject = loading
  if (is(Array, loading) && !isEmpty(loading)) {
    // get first object where loading is true and render it's loading message
    loadingObject = loading.find(loadingItem => !isNil(loadingItem) && loadingItem.isLoading)
  }
  if (loadingObject && loadingObject.isLoading) {
    return <LoadingBox message={ loadingObject.message } />
  }
  return null
}

LoadingHandler.propTypes = {
  loading: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

LoadingHandler.defaultProps = { loading: null }

export default LoadingHandler
