import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { is, isEmpty } from 'ramda'

import Icon from '../Icon'
import CountryCardSites from './CountryCardSites'
import CountryCardInvestigators from './CountryCardInvestigators'
import SwitchVirtualVisit from './SwitchVirtualVisit'


// TODO: Split into two CountryCard components, one for sites one for investigators

class CountryCard extends Component {
  render() {
    const { country, cardType } = this.props
    const {
      sites, isStudyArchived, canHaveVirtualVisits, busyTogglingVirtualVisits, toggleVirtualVisitsError, handleOpenAddSiteModal, handleOpenEditSiteModal,
      handleOpenDeleteSiteModal, resetVirtualVisitsError,
    } = this.props // for CountryCardSites
    const { investigators, handleOpenUpdateInvestigatorModal, handleOpenAddInvestigatorModal, handleOpenDeleteInvestigatorModal, handleConfirmVirtualVisits } = this.props // for CountryCardInvestigators

    const { languages, name } = country

    const showSites = cardType === 'sites'
    const showInvestigators = cardType === 'investigators'
    const virtualVisitsEnabled = !!country.virtualVisitsEnabled

    const languageString = (languages && languages.length === 1) ? 'language' : 'languages'
    const languageList = is(Array, languages) && !isEmpty(languages) && languages.map(language => `${language.isoCode}`)

    return (
      <div className="country-card">
        <div className="country-card--titlebar">
          <Icon
            name="language"
            size={ 20 } />
          <span>{ `${name} - ${languages.length} ${languageString} (${languageList.join(', ')})` }</span>
          { canHaveVirtualVisits
          && (
          <SwitchVirtualVisit
            virtualVisitsEnabled={ virtualVisitsEnabled }
            busyTogglingVirtualVisits={ busyTogglingVirtualVisits }
            toggleVirtualVisitsError={ toggleVirtualVisitsError }
            isStudyArchived={ isStudyArchived }
            handleConfirmed={ (reason, enabledVirtualVisits) => this._confirmVirtualVisitsToggle(handleConfirmVirtualVisits, reason, enabledVirtualVisits) }
            handleClose={ () => this._resetVirtualVisitsError(resetVirtualVisitsError) }
          />
          ) }
        </div>
        { showSites && this._renderCountryCardWithSites(sites, isStudyArchived, handleOpenAddSiteModal, handleOpenEditSiteModal, handleOpenDeleteSiteModal) }
        { showInvestigators && this._renderCountryCardWithInvestigators(investigators, handleOpenUpdateInvestigatorModal, handleOpenDeleteInvestigatorModal, handleOpenAddInvestigatorModal, isStudyArchived) }
      </div>
    )
  }


  _confirmVirtualVisitsToggle = (callback, reason, enabledVirtualVisits) => {
    try {
      callback(reason, enabledVirtualVisits)
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _resetVirtualVisitsError = (resetVirtualVisitsError) => {
    resetVirtualVisitsError()
  }

  _renderCountryCardWithSites = (sites, isStudyArchived, handleOpenAddSiteModal, handleOpenEditSiteModal, handleOpenDeleteSiteModal) => (
    <CountryCardSites
      sites={ sites }
      isStudyArchived={ isStudyArchived }
      handleOpenAddSiteModal={ handleOpenAddSiteModal }
      handleOpenEditSiteModal={ handleOpenEditSiteModal }
      handleOpenDeleteSiteModal={ handleOpenDeleteSiteModal }
      />
  )

  _renderCountryCardWithInvestigators = (investigators, handleOpenUpdateInvestigatorModal, handleOpenDeleteInvestigatorModal, handleOpenAddInvestigatorModal, isStudyArchived) => (
    <CountryCardInvestigators
      investigators={ investigators }
      isStudyArchived={ isStudyArchived }
      handleOpenUpdateInvestigatorModal={ handleOpenUpdateInvestigatorModal }
      handleOpenDeleteInvestigatorModal={ handleOpenDeleteInvestigatorModal }
      handleOpenAddInvestigatorModal={ handleOpenAddInvestigatorModal } />
  )
}

CountryCard.propTypes = {
  isStudyArchived: PropTypes.bool,
  canHaveVirtualVisits: PropTypes.bool,
  country: PropTypes.object.isRequired,
  sites: PropTypes.array,
  investigators: PropTypes.array,
  cardType: PropTypes.string.isRequired,
  handleOpenAddSiteModal: PropTypes.func,
  handleOpenEditSiteModal: PropTypes.func,
  handleOpenDeleteSiteModal: PropTypes.func,
  handleOpenAddInvestigatorModal: PropTypes.func,
  handleOpenUpdateInvestigatorModal: PropTypes.func,
  handleOpenDeleteInvestigatorModal: PropTypes.func,
  handleConfirmVirtualVisits: PropTypes.func.isRequired,
  busyTogglingVirtualVisits: PropTypes.bool.isRequired,
  toggleVirtualVisitsError: PropTypes.object,
  resetVirtualVisitsError: PropTypes.func.isRequired,
}

CountryCard.defaultProps = {
  toggleVirtualVisitsError: null,
  isStudyArchived: false,
  canHaveVirtualVisits: false,
  sites: [],
  investigators: [],
  handleOpenAddSiteModal: () => { console.log('function not supplied') }, // eslint-disable-line no-console
  handleOpenEditSiteModal: () => { console.log('function not supplied') }, // eslint-disable-line no-console
  handleOpenDeleteSiteModal: () => { console.log('function not supplied') }, // eslint-disable-line no-console
  handleOpenAddInvestigatorModal: () => { console.log('function not supplied') }, // eslint-disable-line no-console
  handleOpenUpdateInvestigatorModal: () => { console.log('function not supplied') }, // eslint-disable-line no-console
  handleOpenDeleteInvestigatorModal: () => { console.log('function not supplied') }, // eslint-disable-line no-console
}

export default CountryCard
