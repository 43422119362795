import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MainTextArea extends Component {
  render() {
    const { label, textAreaStyle, onInputChanged, returnFullInputEvent, ...rest } = this.props

    return (
      <div>
        { this._renderLabel(label) }
        <textarea
          className={ ['main-input-textarea'].concat(textAreaStyle || []).join(' ') }
          onChange={ this._inputChanged(onInputChanged, returnFullInputEvent) }
          { ...rest } />
      </div>
    )
  }

  _renderLabel = label => (label ? (
    <div className="main-input-title">
      { label }
    </div>
  ) : null)

  _inputChanged = (cb, returnFullInputEvent) => (e) => {
    if (returnFullInputEvent) {
      cb(e)
    } else {
      cb(e.target.value)
    }
  }
}

MainTextArea.propTypes = {
  returnFullInputEvent: PropTypes.bool,
  label: PropTypes.string,
  textAreaStyle: PropTypes.string,
  onInputChanged: PropTypes.func.isRequired,
}

MainTextArea.defaultProps = {
  returnFullInputEvent: false,
  label: "",
  textAreaStyle: "",
}

export default MainTextArea
