import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import RootSaga from '../sagas'
import { reducer as StudyReducer } from './StudyRedux'
import { reducer as SiteReducer } from './SiteRedux'
import { reducer as CountryReducer } from './CountryRedux'
import { reducer as InvestigatorReducer } from './InvestigatorRedux'
import { reducer as MilestoneReducer } from './MilestoneRedux'
import { reducer as SettingsReducer } from './SettingsRedux'

import configureStore from './CreateStore'

export default (async () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = combineReducers({
    studies: StudyReducer,
    sites: SiteReducer,
    countries: CountryReducer,
    investigators: InvestigatorReducer,
    milestones: MilestoneReducer,
    settings: SettingsReducer,
    router: routerReducer,
  })
  /* ------------- Await root saga setup ------------- */
  const rootSaga = await RootSaga
  /* ------------- Configure Store ------------- */
  return configureStore(rootReducer, rootSaga)
})()
