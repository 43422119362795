import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import LoadingHandler from '../../components/loading/LoadingHandler'

const LoadingContainer = (props) => {
  const { busyFetchingStudies } = props
  if (busyFetchingStudies) {
    const loadingArray = [
      {
        isLoading: busyFetchingStudies,
        message: "Loading Study Admin Portal",
      }]
    return (
      <div className="loading-container">
        <LoadingHandler loading={ loadingArray } />
      </div>
    )
  }
  return null
}

LoadingContainer.propTypes = { busyFetchingStudies: PropTypes.bool.isRequired }

const mapStateToProps = state => ({ busyFetchingStudies: state.studies.busyFetchingStudies })

export default connect(mapStateToProps, null)(LoadingContainer)
