import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const Dropdown = (props) => {
  const { options, selectedOption, onSelect, placeholder, clearable, dropdownClassNames, ...rest } = props

  return (
    <Select
      className={ ["portal-select-input u-margin--vertical", dropdownClassNames].join(' ') }
      isClearable={ clearable }
      value={ selectedOption }
      options={ options }
      placeholder={ placeholder }
      onChange={ onSelect }
      { ...rest } />
  )
}

Dropdown.propTypes = {
  selectedOption: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  dropdownClassNames: PropTypes.string,
}

Dropdown.defaultProps = {
  placeholder: "Select option",
  clearable: false,
  selectedOption: null,
  options: [],
  dropdownClassNames: "",
}

export default Dropdown
