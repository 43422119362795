import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MainInput from '../inputs/MainInput'
import ClickableIcon from '../ClickableIcon'

class PlanningTableSubRowAssociatedToVisit extends Component {
  constructor(props) {
    super(props)
    this.state = { initialOffset: props.selectedVisit.daysOffset }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selectedVisit.daysOffset !== state.initialOffset && (state.initialOffset !== "" || props.selectedVisit.daysOffset !== "0")) {
      return { initialOffset: props.selectedVisit.daysOffset }
    }
    return null
  }

  render() {
    const { initialOffset } = this.state
    const { selectedVisit, handleDelete, handleOffsetChanged, isDisabled } = this.props

    return (
      <tr>
        <td>
          { !isDisabled && (
          <ClickableIcon
            id="button-delete-visit"
            name="delete"
            color="rgba(49, 90, 168, 1)"
            size={ 23 }
            handleClick={ () => handleDelete(selectedVisit.value) } />
          ) }
        </td>
        <td>
          { selectedVisit.value }
        </td>
        <td>
          { this._renderInput(isDisabled, handleOffsetChanged, selectedVisit, initialOffset) }
        </td>
      </tr>
    )
  }

  _renderInput = (isDisabled, handleOffsetChanged, selectedVisit, initialOffset) => {
    if (!isDisabled) {
      return (
        <MainInput
          value={ initialOffset }
          inputClass="visit-offset"
          returnFullInputEvent={ true }
          inputType="numberWithSign"
          autoComplete="off"
          onInputChanged={ event => this._handleInputChanged(event, handleOffsetChanged, selectedVisit) }
       />
      )
    }
    return initialOffset
  }

  _handleInputChanged = (event, handleOffsetChanged, selectedVisit) => {
    this.setState({ initialOffset: event.target.value })
    try {
      // set empty value to "0" for discard-save logic
      handleOffsetChanged({ ...selectedVisit, daysOffset: event.target.value === "" ? "0" : event.target.value })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
}

PlanningTableSubRowAssociatedToVisit.propTypes = {
  selectedVisit: PropTypes.object,
  isDisabled: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  handleOffsetChanged: PropTypes.func.isRequired,
}
PlanningTableSubRowAssociatedToVisit.defaultProps = {
  selectedVisit: null,
  isDisabled: false,
}

export default PlanningTableSubRowAssociatedToVisit
