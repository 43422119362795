import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'

import ClickableIcon from '../ClickableIcon'

const InvestigatorListTable = (props) => {
  const {
    investigators, handleOpenUpdateInvestigatorModal, handleOpenDeleteInvestigatorModal, hasApplicationRole,
    isStudyArchived,
  } = props

  return (
    <div className="table-o">
      <table className="country-card-list-table u-margin--top">
        <thead>
          <tr>
            <th className="investigator-list-header-delete" />
            <th className="investigator-list-header-investigator">
              Investigator
            </th>
            <th className="investigator-list-header-edit" />
            <th className="investigator-list-header-sitepermissions">
              Site ID Permissions
            </th>
          </tr>
        </thead>
        <tbody>
          { is(Array, investigators) && investigators.map(investigator => (
            <tr key={ investigator.id }>
              <td className="investigator-list-row-delete country-card-list-icon">
                <ClickableIcon
                  id="button-delete-investigator"
                  name="delete"
                  size={ 20 }
                  disabled={ hasApplicationRole && isStudyArchived }
                  handleClick={ handleOpenDeleteInvestigatorModal(investigator) } />
              </td>
              <td className="investigator-list-row-investigator">
                { investigator.displayName }
              </td>
              <td className="investigator-list-row-edit country-card-list-icon">
                <ClickableIcon
                  id="button-edit-investigator"
                  name="edit"
                  size={ 20 }
                  disabled={ hasApplicationRole && isStudyArchived }
                  handleClick={ handleOpenUpdateInvestigatorModal(investigator) } />
              </td>
              <td className="investigator-list-row-sitepermissions">
                { is(Array, investigator.sites) && investigator.sites.map(site => `${site.id}`).join(', ') }
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  )
}

InvestigatorListTable.propTypes = {
  investigators: PropTypes.array,
  handleOpenDeleteInvestigatorModal: PropTypes.func.isRequired,
  handleOpenUpdateInvestigatorModal: PropTypes.func.isRequired,
  hasApplicationRole: PropTypes.bool,
  isStudyArchived: PropTypes.bool,
}

InvestigatorListTable.defaultProps = {
  investigators: [],
  hasApplicationRole: false,
  isStudyArchived: false,
}

export default InvestigatorListTable
