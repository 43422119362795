import { call, put } from 'redux-saga/effects'

import MilestoneActions from '../redux/MilestoneRedux'

import queries from '../config/ApiConfig'

import addTitleToErrorObject from '../utils/ErrorHelper'

export function* fetchMilestones(api) {
  const { ok, data } = yield call(api.get, queries.Milestones())
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(MilestoneActions.fetchMilestonesSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading milestones failed`)
    yield put(MilestoneActions.fetchMilestonesFailure(errorObject))
  }
}
export function* fetchMilestonesByStudy(api, action) {
  const { studyId } = action
  const { ok, data } = yield call(api.get, queries.MilestonesByStudyId(studyId))
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(MilestoneActions.fetchMilestonesByStudySuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Loading milestones for ${studyId} failed`)
    yield put(MilestoneActions.fetchMilestonesByStudyFailure(errorObject))
  }
}

export function* addMilestone(api, action) {
  const { studyId, milestoneToAdd } = action
  const { plannedOn, description } = milestoneToAdd
  const { ok, data } = yield call(api.post, queries.MilestonesByStudyId(studyId), {
    plannedOn,
    description,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(MilestoneActions.addMilestoneSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Adding milestone failed`)
    yield put(MilestoneActions.addMilestoneFailure(errorObject))
  }
}

export function* cancelMilestone(api, action) {
  const { studyId, milestoneId } = action
  const { ok, data } = yield call(api.post, queries.CancelMilestone(studyId, milestoneId))
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(MilestoneActions.cancelMilestoneSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Canceling milestone failed`)
    yield put(MilestoneActions.cancelMilestoneFailure(errorObject))
  }
}

export function* updateMilestone(api, action) {
  const { studyId, milestoneId, updatedMilestone } = action
  const { plannedOn, description } = updatedMilestone
  const { ok, data } = yield call(api.put, queries.Milestone(studyId, milestoneId), {
    plannedOn,
    description,
  })
  const embeddedData = (data && data._embedded && data._embedded.item) || data
  if (ok) {
    yield put(MilestoneActions.updateMilestoneSuccess(embeddedData))
  } else {
    const errorObject = addTitleToErrorObject(data, `Updating milestone failed`)
    yield put(MilestoneActions.updateMilestoneFailure(errorObject))
  }
}
