import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'

import history from '../utils/HistoryHelper'

import NavbarBrand from '../components/navbar/NavbarBrand'
import NavbarHeader from '../components/navbar/NavbarHeader'

import MainContainer from './layout/MainContainer'
import NavbarContainer from './layout/NavbarContainer'
import SidebarContainer from './layout/SidebarContainer'
import ViewContainer from './layout/ViewContainer'
import StudyContainer from './study/StudyContainer'
import StartContainer from './content/StartContainer'
import MilestonesContainer from './content/MilestonesContainer'
import LoadingContainer from './content/LoadingContainer'
import GlobalConfigurationContainer from './content/GlobalConfigurationContainer'

const routes = [
  {
    id: 'start-container',
    path: '/',
    exact: true,
    main: <StartContainer />,
  },
  {
    id: 'milestones-container',
    path: '/milestones',
    exact: true,
    main: <MilestonesContainer />,
  },
  {
    id: 'study-container',
    path: '/study/:study_id/*',
    main: <StudyContainer />,
  },
  {
    id: 'global-configuration-container',
    path: '/globalconfiguration',
    exact: true,
    main: <GlobalConfigurationContainer />,
  },
]

export default class RouterContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { sidebarCollapsed: false }
  }

  render() {
    const { sidebarCollapsed } = this.state

    return (
      <div className="router-container">
        <NavbarContainer>
          <NavbarBrand />
          <NavbarHeader handleSidebarClick={ this._toggleSidebar(sidebarCollapsed) } />
        </NavbarContainer>
        <ViewContainer>
          <SidebarContainer sidebarCollapsed={ sidebarCollapsed } history={ history } />
          <MainContainer>
            <LoadingContainer />
            <Routes>
              { routes.map(route => (
                <Route
                  key={ route.id }
                  path={ route.path }
                  element={ route.main } />
              )) }
            </Routes>
          </MainContainer>
        </ViewContainer>
      </div>
    )
  }

  _toggleSidebar = isisSidebarCollapsed => () => {
    this.setState({ sidebarCollapsed: !isisSidebarCollapsed })
  }
}
