import ApiSauce from 'apisauce'
// because axios is in already in the apisauce package
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { CancelToken } from 'axios'

import HttpStatusCodes from '../assets/json/HttpStatusCodes.json'
import ApplicationConfig from '../config/ApplicationConfig'
import getConfigOrEnvVariable from '../utils/ConfigHelper'
import MsalConfig, { additional } from '../config/msalConfig'

const source = CancelToken.source()

// Configure API middleware here
export default (async () => {
  // wait for configuration
  const appCfg = await ApplicationConfig
  const msalCfg = await MsalConfig

  // create api service options
  const apiServiceOptions = {
    headers: { Accept: 'application/json' },
    cancelToken: source.token,
    withCredentials: true,
  }

  // add baseURL
  apiServiceOptions.baseURL = getConfigOrEnvVariable(appCfg.apiBaseUrl, process.env.REACT_APP_API_BASE_URL)

  // create api service
  const ApiService = ApiSauce.create(apiServiceOptions)

  // start monitoring api service and set timeouts
  // add token monitor after each call so we can re-acquire a token when needed, and set it to the APIs headers
  // Request interceptor for API calls
  ApiService.axiosInstance.interceptors.request.use(
    async (request) => {
      const account = window.sessionHandler.loggedInUser
      if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.")
      }
      const response = await window.msalInstance.acquireTokenSilent({
        ...msalCfg.apiRequest,
        account,
      })
      window.sessionHandler.expiry = additional.timeoutInMinutes
      request.headers.Authorization = `Bearer ${response.accessToken}`

      window.sessionHandler.storeToken(response.accessToken)
      return request
    },
    (error) => {
      Promise.reject(error)
    },
  )

  // add timeout monitor after each call so we can reset the timer
  const timeoutMonitor = () => {
    clearTimeout(window.sessionTimeout)
    window.sessionTimeout = setTimeout(() => window.sessionHandler.checkTokenIsExpiredAndLogout(), (additional.timeoutInMinutes * 60 * 1000) + 3000) // add 3 seconds so 'tokenIsExpiredOrNull' can make better calculations in case of accidental overlap
  }
  ApiService.addMonitor(timeoutMonitor)

  // add check before each request, to see if our token isn't expired
  ApiService.addRequestTransform(() => {
    // check if token has passed 30minutes timeout
    if (window.sessionHandler != null && window.sessionHandler.tokenIsExpiredOrNull) {
      window.sessionHandler.checkTokenIsExpiredAndLogout()
      // and cancel the request because the logout is in a race with the request being sent out
      source.cancel()
    }
  })

  // add transform when response is an error
  ApiService.addResponseTransform((response) => {
    if (!response.ok) {
      // eslint-disable-next-line
      switch (response.problem) {
        case ApiSauce.CLIENT_ERROR:
          response.ok = false
          response.data = { 
          ...{ 
            error: response.status,
            details: `Client error: ${response.status} - ${HttpStatusCodes[response.status]}`,
          }, 
          ...response.data, 
          }
          break
        case ApiSauce.TIMEOUT_ERROR:
          response.ok = false
          response.data = { 
            ...{
              error: response.status,
              hint: "Please try again.",
              details: `Timeout error: ${response.status} - Server did not respond in time.`,
            }, 
            ...response.data, 
          }
          break
        case ApiSauce.CONNECTION_ERROR:
          response.ok = false
          response.data = { 
            ...{
              error: response.status,
              hint: "Please contact a system admin.",
              details: `Connection error: ${response.status} - Server not available.`,
            }, 
            ...response.data,
          }
          break
        case ApiSauce.NETWORK_ERROR:
          response.ok = false
          response.data = { 
            ...{
              error: response.status,
              hint: "Are you sure you are connected to the internet?",
              details: `Network error: ${response.status} - Network not available.`,
            }, 
            ...response.data,
          }
          break
      }
    }
  })
  return ApiService
})()
