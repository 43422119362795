import React, { Component } from 'react'
import PropTypes from 'prop-types'

class MainInputGroup extends Component {
  render() {
    const { label, children } = this.props

    return (
      <div className="main-input-group">
        { this._renderLabel(label) }
        <div className="main-input-group-items">
          { children }
        </div>
      </div>
    )
  }

  _renderLabel = label => (label ? (
    <div className="main-input-title">
      { label }
    </div>
  ) : null)
}

MainInputGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
}

MainInputGroup.defaultProps = { label: "" }

export default MainInputGroup
