import React, { Component } from 'react'
import { isNil, path } from 'ramda'

import LogoutButton from '../buttons/LogoutButton'

class ProfileBadge extends Component {
  constructor(props) {
    super(props)
    this.state = { user: null }
  }

  componentDidMount() {
    const user = window.sessionHandler.loggedInUser
    if (user) {
      this.setState({ user })
    }
  }

  render() {
    const { user } = this.state
    return (
      <div className="profile-badge">
        <p className="profile-badge--name u-margin--zero">
          { isNil(path(['name'], user)) ? 'User name not configured' : user.name }
        </p>
        <LogoutButton handleLogout={ () => window.sessionHandler.resetExpiryAndLogout() } />
      </div>
    )
  }
}

export default ProfileBadge
