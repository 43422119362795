import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isNilOrEmpty } from 'ramdasauce'

import HintLabel from '../HintLabel'
import ConfirmationButtons from '../buttons/ConfirmationButtons'
import ErrorHandler from '../error/ErrorHandler'

class CancelMilestoneForm extends Component {
  render() {
    const { handleCanceled, handleConfirmed, error, loading, milestone } = this.props
    const { plannedOn, description } = milestone

    let milestoneInfo = `"${moment.utc(plannedOn).format('DD-MMM-YYYY')}`
    if (!isNilOrEmpty(description)) {
      milestoneInfo += ` - ${description}`
    }
    milestoneInfo += '"'
    return (
      <div>
        { error && this._renderError(error) }
        <HintLabel size={ 16 }>
          Please confirm canceling the following milestone schedule:
          <br />
          <strong>{ milestoneInfo }</strong>
        </HintLabel>
        <ConfirmationButtons
          onCancel={ this._onCancel(handleCanceled) }
          onConfirm={ this._onConfirm(handleConfirmed) }
          cancelDisabled={ loading }
          confirmDisabled={ loading } />
      </div>
    )
  }

  _renderError = error => (
    <ErrorHandler
      containerClass="u-margin--top"
      error={ error } />
  )

  _onCancel = cb => () => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  _onConfirm = cb => () => {
    try {
      cb()
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }
}

CancelMilestoneForm.propTypes = {
  handleCanceled: PropTypes.func.isRequired,
  handleConfirmed: PropTypes.func.isRequired,
  milestone: PropTypes.object.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
}

CancelMilestoneForm.defaultProps = { error: null }

export default CancelMilestoneForm
