import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'

import CollapsePickerSelecter from './CollapsePickerSelecter'

const CollapsePicker = (props) => {
  const { title, selecterTitle, onSelectTitle, headerClass, containerClass, selecterClass, isOpen, onSelecterClick, children } = props

  const containerClasses = cc([
    containerClass,
    "collapsepicker-container",
    { "collapsepicker-container-open": isOpen },
  ])

  const headerClasses = cc([headerClass, "collapsepicker-header"])
  const selecterClasses = cc([selecterClass, "collapsepicker-selecter"])

  return (
    <div className={ containerClasses }>
      <span className={ headerClasses }>
        { title }
      </span>
      <CollapsePickerSelecter
        className={ selecterClasses }
        onClick={ onSelecterClick }
        title={ (onSelectTitle && isOpen && onSelectTitle) || selecterTitle }
        shouldRotate={ isOpen }
        onKeyPress={ onSelecterClick }
        tabIndex={ 0 }
        role="button" />
      <div className="collapsepicker-items">
        { children }
      </div>
    </div>
  )
}

CollapsePicker.propTypes = {
  title: PropTypes.string.isRequired,
  selecterTitle: PropTypes.string.isRequired,
  onSelectTitle: PropTypes.string,
  headerClass: PropTypes.string,
  containerClass: PropTypes.string,
  selecterClass: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSelecterClick: PropTypes.func.isRequired,
}

CollapsePicker.defaultProps = {
  isOpen: false,
  onSelectTitle: '',
  headerClass: '',
  containerClass: '',
  selecterClass: '',
}

export default CollapsePicker
