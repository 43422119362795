import React from 'react'
import PropTypes from 'prop-types'
import { is, isEmpty } from 'ramda'

import ClickableIcon from '../ClickableIcon'

const SiteListTable = (props) => {
  const { sites, isStudyArchived, handleOpenEditSiteModal, handleOpenDeleteSiteModal } = props

  return (
    <div className="table-o">
      <table className="country-card-list-table u-margin--top">
        <thead>
          <tr>
            <th className="site-list-header-edit" />
            <th className="site-list-header-delete" />
            <th className="site-list-header-siteid">
              Site ID
            </th>
            <th className="site-list-header-location">
              Location
            </th>
            <th className="site-list-header-timezone">
              Time zone
            </th>
            <th className="site-list-header-patientrange">
              Patient ID Range
            </th>
          </tr>
        </thead>
        <tbody>
          { is(Array, sites) && !isEmpty(sites) && sites.map(site => (
            <tr key={ site.id }>
              <td className="site-list-row-edit country-card-list-icon">
                <ClickableIcon
                  id="button-edit-site"
                  name="edit"
                  disabled={ isStudyArchived }
                  size={ 20 }
                  handleClick={ handleOpenEditSiteModal(site) } />
              </td>
              <td className="site-list-row-delete country-card-list-icon">
                <ClickableIcon
                  id="button-delete-site"
                  name="delete"
                  disabled={ isStudyArchived }
                  size={ 20 }
                  handleClick={ handleOpenDeleteSiteModal(site) } />
              </td>
              <td className="site-list-row-siteid">
                { site.id }
              </td>
              <td className="site-list-row-location">
                { site.description }
              </td>
              <td className="site-list-row-timezone">
                { is(String, site.location) && !isEmpty(site.location) ? site.location.replace(/\//g, "\u200B/\u200B").replace(/_/g, "\u00A0") : site.location }
              </td>
              <td className="site-list-row-patientrange">
                { `${site.patientStartRange}-${site.patientEndRange}` }
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  )
}

SiteListTable.propTypes = {
  isStudyArchived: PropTypes.bool,
  sites: PropTypes.array,
  handleOpenEditSiteModal: PropTypes.func.isRequired,
  handleOpenDeleteSiteModal: PropTypes.func.isRequired,
}

SiteListTable.defaultProps = {
  sites: [],
  isStudyArchived: false,
}

export default SiteListTable
