import moment from 'moment'
import { equals } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'

export const MilestoneStatuses = {
  PENDING: 0,
  COMPLETED: 1,
  FAILED: 2,
  CANCELED: 3,
  properties: {
    0: { label: 'Pending' },
    1: { label: 'Completed' },
    2: { label: 'Failed' },
    3: { label: 'Canceled' },
  },
}

if (Object.freeze) {
  Object.freeze(MilestoneStatuses)
}

export function getMilestoneLabel(status) {
  return MilestoneStatuses.properties[status].label
}

export function getMilestoneLabelWithDate(status, completedOn, failedOn) {
  let label = getMilestoneLabel(status)
  if (!isNilOrEmpty(completedOn) && equals(status, MilestoneStatuses.COMPLETED)) {
    label += ` on ${moment.utc(completedOn).format('DD-MMM-YYYY')}`
  }
  if (!isNilOrEmpty(failedOn) && equals(status, MilestoneStatuses.FAILED)) {
    label += ` on ${moment.utc(failedOn).format('DD-MMM-YYYY')}`
  }
  return label
}
