import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import withRouter from '../../components/wrapperReactRouterDom'
import MilestoneActions from '../../redux/MilestoneRedux'
import TabContainer from '../layout/TabContainer'
import HintLabel from '../../components/HintLabel'
import MilestoneTable from '../../components/tables/MilestoneTable'

class MilestonesContainer extends Component {
  componentDidMount() {
    const { fetchMilestones, busyFetchingMilestones } = this.props
    if (!busyFetchingMilestones) {
      fetchMilestones()
    }
  }

  render() {
    const { fetchMilestonesError, busyFetchingMilestones, milestones } = this.props

    return (
      <TabContainer>
        <HintLabel hintClass="u-margin--bottom">
          <strong>All Milestones</strong>
        </HintLabel>
        <MilestoneTable
          milestones={ milestones }
          canFilterMilestones={ true }
          loadingMilestones={ busyFetchingMilestones }
          errorLoadingMilestones={ fetchMilestonesError } />
      </TabContainer>
    )
  }
}

MilestonesContainer.propTypes = {
  // milestones
  milestones: PropTypes.array,
  fetchMilestones: PropTypes.func.isRequired,
  busyFetchingMilestones: PropTypes.bool.isRequired,
  fetchMilestonesError: PropTypes.object,
}

MilestonesContainer.defaultProps = {
  // milestones
  milestones: [],
  fetchMilestonesError: {},
}

const mapStateToProps = state => ({
  // milestones
  milestones: state.milestones.milestonesList,
  fetchMilestonesError: state.milestones.fetchMilestonesError,
  busyFetchingMilestones: state.milestones.busyFetchingMilestones,
})

const mapDispatchToProps = dispatch => ({ fetchMilestones: () => dispatch(MilestoneActions.fetchMilestones()) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilestonesContainer))
