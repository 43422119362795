import React from 'react'
import PropTypes from 'prop-types'

import MainButton from './MainButton'
import MainButtonGroup from './MainButtonGroup'

const ConfirmationButtons = (props) => {
  const { buttonGroupClass, onConfirm, onCancel, cancelDisabled, confirmDisabled, confirmLabel, cancelLabel, disableButtons } = props
  return (
    <MainButtonGroup buttonGroupClass={ buttonGroupClass }>
      <MainButton
        label={ cancelLabel }
        buttonClass="inverted-blue"
        isDisabled={ disableButtons || cancelDisabled }
        handleClick={ onCancel }
        id="button-cancel" />
      <MainButton
        label={ confirmLabel }
        buttonClass="blue"
        isDisabled={ disableButtons || confirmDisabled }
        handleClick={ onConfirm }
        id="button-confirm" />
    </MainButtonGroup>
  )
}

ConfirmationButtons.propTypes = {
  buttonGroupClass: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  disableButtons: PropTypes.bool,
}

ConfirmationButtons.defaultProps = {
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  buttonGroupClass: "",
  confirmDisabled: false,
  cancelDisabled: false,
  disableButtons: false,
}

export default ConfirmationButtons
